import { PropsWithChildren } from "react";
import App from "../App";
import { Grommet } from "grommet";
import { theme } from "../themes/index";
import { useThemeDetect } from "./hooks/useThemeDetect";

interface WrapperProps {}

const Wrapper = (p: PropsWithChildren<WrapperProps>) => {
  const isDark = false; //useThemeDetect();
  // console.log(`The reported browser theme is ${isDark ? "dark" : "light"}`);
  return (
    <>
      <Grommet
        theme={theme}
        themeMode={isDark ? "dark" : "light"}
        full={true}
        className="WHY"
      >
        <App />
      </Grommet>
    </>
  );
};

export default Wrapper;
