import { deepFreeze } from "grommet/utils";
import percentToHex from "../helpers/percentToHex";
export const mytheme = deepFreeze({
  name: "mytheme",
  rounding: 4,
  spacing: 24,
  defaultMode: "light",
  global: {
    colors: {
      brand: {
        dark: "#00739D",
        light: "#cbf1f2",
      },
      reverse_brand: {
        dark: "#cbf1f2",
        light: "#00739D",
      },
      "brand-transparent92": {
        dark: "#00739D" + percentToHex(92),
        light: "#cbf1f2" + percentToHex(92),
      },
      "brand-transparent40": {
        dark: "#00739D" + percentToHex(40),
        light: "#cbf1f2" + percentToHex(40),
      },
      "brand-transparent20": {
        dark: "#00739D" + percentToHex(20),
        light: "#cbf1f2" + percentToHex(20),
      },
      "tint-transparent40": {
        dark: "#111111" + percentToHex(40),
        light: "#111111" + percentToHex(40),
      },
      transparent100: {
        dark: "#FFFFFF" + percentToHex(100),
        light: "#111111" + percentToHex(100),
      },
      background: {
        dark: "#111111",
        light: "#FFFFFF",
      },
      icons: {
        dark: "#222222",
        light: "#00739D",
      },
      "background-back": {
        dark: "#111111",
        light: "#EEEEEE",
      },
      "background-front": {
        dark: "#222222",
        light: "#FFFFFF",
      },
      "bright-white-always": {
        dark: "#FFFFFF",
        light: "#FFFFFF",
      },
      "background-contrast": {
        dark: "#FFFFFF11",
        light: "#11111111",
      },
      text: {
        dark: "#EEEEEE",
        light: "#333333",
      },
      "text-strong": {
        dark: "#FFFFFF",
        light: "#000000",
      },
      "text-weak": {
        dark: "#CCCCCC",
        light: "#444444",
      },
      "text-xweak": {
        dark: "#999999",
        light: "#666666",
      },
      border: {
        dark: "#444444",
        light: "#CCCCCC",
      },
      hover_custom: {
        dark: "#04ccc1",
        light: "#00739D",
      },
      special: {
        dark: "#ff8d6d",
        light: "#000033",
      },
      control: "brand",
      "active-background": "background-contrast",
      "active-text": "text-strong",
      "selected-background": "brand",
      "selected-text": "text-strong",
      "status-critical": "#FF4040",
      "status-warning": "#FFAA15",
      "status-neutral": "#00739D",
      "status-ok": "#00C781",
      "status-unknown": "#CCCCCC",
      "status-disabled": "#CCCCCC",
      "graph-0": "brand",
      "graph-1": "status-warning",
    },
    font: {
      family: "Helvetica",
    },
    active: {
      background: "active-background",
      color: "active-text",
    },
    hover: {
      background: "hover_custom",
      color: "active-text",
    },
    selected: {
      background: "selected-background",
      color: "selected-text",
    },
  },

  checkBox: {
    color: {
      dark: "#ff8d6d",
      light: "status-ok",
    },
    border: {
      color: "status-ok",
    },
    check: {
      radius: "0px",
    },
  },
  formField: {
    label: {
      margin: {
        vertical: "0px",
        horizontal: "xxsmall",
      },
    },
  },
  accordion: {
    icons: {
      color: "reverse_brand",
    },
    hover: {
      heading: {
        color: "special",
      },
    },
  },

  button: {
    primary: {
      color: "brand",
    },
    border: {
      radius: "0px",
    },
    size: {
      large: {
        border: {
          radius: "0px",
        },
      },
    },
  },
  chart: {},
  diagram: {
    line: {},
  },
  meter: {},
  tip: {
    content: {
      background: {
        color: "background",
      },
      elevation: "none",
      round: false,
    },
  },
  layer: {
    background: {
      dark: "#111111",
      light: "#FFFFFF",
    },
  },
});

export default mytheme;
