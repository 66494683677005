import { Footer, Text, Button, Box, ResponsiveContext } from "grommet";
import * as I from "grommet-icons";
import { useContext } from "react";
import { LanguageContext } from "../App";
import { constants as c } from "../constants/constants";
import { translations as t } from "../translates/translations";

const FooterBar = (props: any) => {
  const icon_color = "icons";
  const l = useContext(LanguageContext);
  const size = useContext(ResponsiveContext);
  const gridStyle = {
    display: "grid",
    gap: "0rem",
    gridTemplateColumns: `repeat(4, minmax(200px, 1fr))`,
  };
  return size !== "small" ? (
    <Footer
      background={"brand"}
      pad="medium"
      as="footer"
      direction="column"
      align="left"
    >
      <Box style={gridStyle}>
        <Box className="column1">
          <Box direction="row">
            <Button
              size="small"
              href={c.facebook_url}
              icon={<I.Facebook color={icon_color} />}
              hoverIndicator
              label="Facebook"
              style={{ border: "none" }}
            />
          </Box>
          <Box direction="row">
            <Button
              size="small"
              href={c.instagram_url}
              icon={<I.Instagram color={icon_color} />}
              hoverIndicator
              label="Instagram"
              style={{ border: "none" }}
            />
          </Box>
        </Box>
        <Box className="column2">
          <Box direction="row">
            <Button
              size="small"
              href={`tel:${c.phone_number}`}
              icon={<I.Phone color={icon_color} />}
              hoverIndicator
              label={c.phone_number}
              style={{ border: "none" }}
            />
          </Box>
          <Box direction="row">
            <Button
              size="small"
              href={`mailto:${c.email}`}
              icon={<I.MailOption color={icon_color} />}
              hoverIndicator
              label={c.email}
              style={{ border: "none" }}
            />
          </Box>
        </Box>
        <Box className="column3">
          <Box direction="row">
            <Button
              size="small"
              href={`${c.g_maps_adress}`}
              icon={<I.MapLocation color={icon_color} />}
              hoverIndicator
              label={c.physical_adress}
              style={{ border: "none" }}
            />
          </Box>
          <Box direction="row">
            <Button
              size="small"
              icon={<I.Organization color={icon_color} />}
              hoverIndicator
              label={`Org Nr. ${c.organization_id}`}
              style={{ border: "none" }}
            />
          </Box>
          <Box direction="row">
            <Button
              size="small"
              icon={<I.Money color={icon_color} />}
              hoverIndicator
              label={`Godkänd för F-skatt`}
              style={{ border: "none" }}
            />
          </Box>
        </Box>
        <Box className="column4">
          <Box direction="row">
            <Button
              size="small"
              icon={<I.Clock color={icon_color} />}
              hoverIndicator
              label={t.Workdays[l] + ": " + c.work_hours_Monday_Friday}
              style={{ border: "none" }}
            />
          </Box>
          <Box direction="row">
            <Button
              size="small"
              icon={<I.Clock color={"brand"} />}
              hoverIndicator
              label={t.Weekends[l] + ": " + t.CLOSED[l]}
              style={{ border: "none" }}
            />
          </Box>
        </Box>
      </Box>
      <div style={{ minHeight: "2em" }}></div>
      <Text size="xsmall" alignSelf="center">
        {" "}
        {c.organization_name + " " + new Date().getFullYear()}
      </Text>
    </Footer>
  ) : (
    <Footer
      background={"brand"}
      pad="medium"
      as="footer"
      direction="column"
      align="left"
    >
      <Box
        align="left"
        alignContent="left"
        direction="column"
        responsive={true}
        justify={"start"}
      >
        <Box direction="row">
          <Button
            size="small"
            href={c.facebook_url}
            icon={<I.Facebook color={icon_color} />}
            hoverIndicator
            label="Facebook"
            style={{ border: "none" }}
          />
        </Box>
        <Box direction="row">
          <Button
            size="small"
            href={c.instagram_url}
            icon={<I.Instagram color={icon_color} />}
            hoverIndicator
            label="Instagram"
            style={{ border: "none" }}
          />
        </Box>
        <Box direction="row">
          <Button
            size="small"
            href={`tel:${c.phone_number}`}
            icon={<I.Phone color={icon_color} />}
            hoverIndicator
            label={c.phone_number}
            style={{ border: "none" }}
          />
        </Box>
        <Box direction="row">
          <Button
            size="small"
            href={`mailto:${c.email}`}
            icon={<I.MailOption color={icon_color} />}
            hoverIndicator
            label={c.email}
            style={{ border: "none" }}
          />
        </Box>
        <Box direction="row">
          <Button
            size="small"
            href={`${c.g_maps_adress}`}
            icon={<I.MapLocation color={icon_color} />}
            hoverIndicator
            label={c.physical_adress}
            style={{ border: "none" }}
          />
        </Box>
        <Box direction="row">
          <Button
            size="small"
            icon={<I.Clock color={icon_color} />}
            hoverIndicator
            label={t.Workdays[l] + ": " + c.work_hours_Monday_Friday}
            style={{ border: "none" }}
          />
        </Box>
        <Box direction="row">
          <Button
            size="small"
            icon={<I.Clock color={"brand"} />}
            hoverIndicator
            label={t.Weekends[l] + ": " + t.CLOSED[l]}
            style={{ border: "none" }}
          />
        </Box>
        <Box direction="row">
          <Button
            size="small"
            icon={<I.Organization color={icon_color} />}
            hoverIndicator
            label={`Org Nr. ${c.organization_id}`}
            style={{ border: "none" }}
          />
        </Box>
        <Box direction="row">
          <Button
            size="small"
            icon={<I.Money color={icon_color} />}
            hoverIndicator
            label={`Godkänd för F-skatt`}
            style={{ border: "none" }}
          />
        </Box>
      </Box>
      <div style={{ minHeight: "2em" }}></div>
      <Text size="xsmall" alignSelf="center">
        {" "}
        {c.organization_name + " " + new Date().getFullYear()}
      </Text>
    </Footer>
  );
};

export default FooterBar;
