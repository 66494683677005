export const constants = {
  organization_name: "Städkamrater",
  organization_id: "969796-5581",
  bokforingen_org: "Visma eEkonomi",
  bokforingen_org_url:
    "https://www.vismaspcs.se/visma-support/visma-eaccounting/se/content/online-help/general-gdpr.htm",
  facebook_url:
    "https://www.facebook.com/St%C3%A4dkamrater-i-Karlstad-103667405802108",
  instagram_url: "https://www.instagram.com/stad.kamrater/",
  phone_number: "+46727290293",
  email: "support@stadkamrater.se",
  g_maps_adress: "https://maps.google.com/?q= Vävaregatan 6, 65462 Karlstad",
  physical_adress: "Vävaregatan 6, 65462 Karlstad",
  g_maps_embedd: `https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2031.5716241591826!2d13.514896515933629!3d59.39017508168014!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x465cb161ebfb5549%3A0xc056f97f124cfeb9!2sV%C3%A4varegatan%206%2C%20654%2062%20Karlstad!5e0!3m2!1sen!2sse!4v1665757999747!5m2!1sen!2sse`,
  work_hours_Monday_Friday: "7.00 - 17.00",
  work_hours_Weekend: "--",
};
