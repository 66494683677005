import { Text, Box, Image, Stack, Paragraph, Button } from "grommet";
import useElementIsOnScreen from "./hooks/useElementIsOnScreen";
import { useContext, useState } from "react";
import { LanguageContext } from "../App";
import { translations as t } from "../translates/translations";
import { capitalizeFirstLetter as cptl } from "../helpers/capitalize";
import load_pic from "../helpers/imageLoader";
import { Link } from "react-router-dom";

interface CardTypeProps {
  /** The string is limited to 26 characters  */
  header: string;
  /** The string is limited to 350 characters  */
  text?: string;
  /** If not passed it will use default image  */
  image?: any;
  size : string
}

const Card = ({ header, text, image = load_pic("mop"),size }: CardTypeProps) => {
  const [ref, isVisible] = useElementIsOnScreen();
  const [btn_hover, set_btn_hover] = useState(false);
  const l = useContext(LanguageContext);

  const handleButtonHover = () => {
    if (btn_hover === true) {
      set_btn_hover(false);
    }
    if (btn_hover === false) {
      set_btn_hover(true);
    }
  };

  return (
    <div onMouseEnter={handleButtonHover} onMouseLeave={handleButtonHover}>
      <Stack anchor="center" ref={ref}>
        <Link to={`/services-list/${header}`}>
          <Box
            animation={"slideRight"}
            height={size === "large"?size:"medium"}
            fill="horizontal"
            direction="column"
            alignContent="center"
            className="img_card"
            background={"brand-transparent92"}
          >
            <Image
              loading="lazy"
              style={{ backgroundColor: "Highlight" }}
              fallback={image}
              fit="cover"
              src={image}
              className={
                isVisible
                  ? `animate-in ${btn_hover && "synthetic_hover_img_card"}`
                  : "animate-mid-out"
              }
            ></Image>
          </Box>
        </Link>
        <Box alignContent="center">
          <Box background={{ color: "tint-transparent40" }}>
            <Text
              truncate
              color={"brand"}
              weight="bold"
              size="xxlarge"
              alignSelf="center"
            >
              {header.substring(0, 350)}
            </Text>
          </Box>
          <Paragraph
            textAlign="center"
            color={"brand"}
            fill
            margin={"xsmall"}
            size="small"
          >
            {text?.substring(0, 350)}
          </Paragraph>
          <Link to={`/services-list/${header}`}>
            <Button
              primary
              alignSelf="center"
              margin={{ top: "2em" }}
              label={`${cptl(t.read_more_about[l])} ${header}`}
              style={{ minWidth: "10em" }}
            ></Button>
          </Link>
        </Box>
      </Stack>
    </div>
  );
};

export default Card;
