import {
  Text,
  Box,
  Table,
  TableHeader,
  TableRow,
  TableCell,
  TableBody,
  Anchor,
  Button,
  Paragraph,
} from "grommet";
import * as I from "grommet-icons";
import { Link } from "react-router-dom";
import { translations as t } from "../../translates/translations";
import { useContext } from "react";
import { LanguageContext } from "../../App";
import { constants as c } from "../../constants/constants";
import load_pic from "../../helpers/imageLoader";
import { useDocumentTitle } from "../hooks/useDocumentTitle";
import setMetaTagForCanonicalUrl from "../../helpers/setMetaTagForCanonicalUrl";

interface ContactsPageProps {
  size: string;
}
const ContactsPage = ({ size }: ContactsPageProps) => {
  setMetaTagForCanonicalUrl();
  const l = useContext(LanguageContext);
  useDocumentTitle(t.Contact_Us[l]);
  const gridStyle = {
    display: "grid",
    gap: "0rem",
    gridTemplateColumns: `repeat(2, minmax(200px, 1fr))`,
  };

  return (
    <>
      <Box style={{ minHeight: "60px" }} background={{ color: "brand" }}></Box>

      <>
        <div style={{ height: "35%" }}>
          <Box
            fill
            background={{
              color: "brand",
              dark: true,
              position: "bottom",
              repeat: "no-repeat",
              size: "cover",
              image: `url(${load_pic("table_top", l)})`,
            }}
          >
            <Text
              color={"brand"}
              size={size === "small" ? "4xl" : "6xl"}
              weight={800}
              textAlign="center"
              margin={"medium"}
              style={{ paddingTop: size === "small" ? "0" : "1em" }}
            >
              {t.Contact_Us[l]}
            </Text>
          </Box>
        </div>
        <div style={size !== "small" ? gridStyle : {}}>
          {size !== "small" && (
            <div>
              <iframe
                title={"iframeafds"}
                src={c.g_maps_embedd}
                width={"100%"}
                height={"100%"}
                style={{ border: 0 }}
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          )}

          <Box fill background={{ color: "brand" }} pad={{ bottom: "2em" }}>
            <Table margin={{ top: "2em", bottom: "2em" }}>
              <TableHeader>
                <TableRow>
                  <TableCell scope="col" border="bottom">
                    {t.Our_Working_Hours[l]}:
                  </TableCell>
                </TableRow>
              </TableHeader>
              <TableBody>
                <TableRow>
                  <TableCell scope="row">
                    <strong>{t.Workdays[l]}</strong>
                  </TableCell>
                  <TableCell>{c.work_hours_Monday_Friday}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row">
                    <strong>{t.Weekends[l]}</strong>
                  </TableCell>
                  <TableCell>{c.work_hours_Weekend}</TableCell>
                </TableRow>
              </TableBody>
            </Table>

            {size === "small" ? (
              <>
                <Box animation={"fadeIn"} margin={"medium"} direction="column">
                  <Text textAlign="center" weight={800}>
                    {t.you_may_call_or_sms_us[l]}:
                  </Text>
                  <Anchor
                    alignSelf="center"
                    color={"special"}
                    label={` ${c.phone_number}`}
                    href={`tel:${c.phone_number}`}
                  />
                </Box>
                <Box animation={"fadeIn"} margin={"medium"} direction="column">
                  <Text weight={800} textAlign="center">
                    {t.you_may_even_reach_us_by_email[l]}:
                  </Text>
                  <Anchor
                    alignSelf="center"
                    color={"special"}
                    label={`${c.email}`}
                    href={`mailto:${c.email}`}
                  />
                </Box>

                <Box gridArea="big-text" animation={"fadeIn"}>
                  <Box direction="column">
                    <Text margin={"medium"} weight={800} textAlign="center">
                      {t.or_by_web_form[l]}:
                    </Text>
                    <Box alignContent="center" align="center">
                      <Link to="/contact_form">
                        <Button
                          color={"special"}
                          alignSelf="center"
                          size={"large"}
                          icon={<I.UserExpert />}
                          primary
                          style={{ border: "0px" }}
                          hoverIndicator
                          label={t.contact_form[l]}
                        />
                      </Link>
                    </Box>
                  </Box>
                </Box>
              </>
            ) : (
              <>
                {" "}
                <Box animation={"fadeIn"} margin={"medium"}>
                  <Anchor
                    color={"special"}
                    label={`${t.you_may_call_or_sms_us[l]} : ${c.phone_number}`}
                    href={`t-tel:${c.phone_number}`}
                  />
                </Box>
                <Box animation={"fadeIn"} margin={"medium"}>
                  <Anchor
                    color={"special"}
                    label={`${t.you_may_even_reach_us_by_email[l]}: ${c.email}`}
                    href={`mailto:${c.email}`}
                  />
                </Box>
                <Box gridArea="big-text" animation={"fadeIn"}>
                  <Box direction="row">
                    <Text margin={"medium"} weight={800}>
                      {t.or_by_web_form[l]}:
                    </Text>
                    <Link to="/contact_form">
                      <Button
                        color={"special"}
                        alignSelf="center"
                        size={"large"}
                        icon={<I.UserExpert />}
                        primary
                        style={{ border: "0px" }}
                        hoverIndicator
                        label={t.contact_form[l]}
                      />
                    </Link>
                  </Box>
                </Box>
                <Paragraph></Paragraph>
                <Paragraph></Paragraph>
                <Paragraph></Paragraph>

              </>
            )}
          </Box>
        </div>
      </>
    </>
  );
};

export default ContactsPage;
