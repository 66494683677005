import { useEffect, useState } from "react";
import { constants as c } from "../../constants/constants";

const useDocumentTitle = (title?: string) => {
  const [document_title, setDoucmentTitle] = useState(
    (title = `${c.organization_name} ${title ? "- " + title : ""}`)
  );
  useEffect(() => {
    document.title = document_title;
  }, [document_title]);

  return [document_title, setDoucmentTitle];
};

export { useDocumentTitle };
