import { Stack, Image, Box, Text, Anchor } from "grommet";
import load_pic from "../helpers/imageLoader";
import { useContext } from "react";
import { LanguageContext } from "../App";
import { translations as t } from "../translates/translations";
import * as I from "grommet-icons";

interface HeroImageProps {
  size: string;
}

const HeroImage = (p: HeroImageProps) => {
  const { size } = p;
  const l = useContext(LanguageContext);
  const pictures = [
    load_pic("hero_pic", size),
    load_pic("mop_mirror", size),
    load_pic("mirror", size),
  ];
  return (
    <Stack anchor="center">
      <Box animation={"fadeIn"} height={"large"} overflow="hidden">
        <Image
          src={pictures[Math.floor(Math.random() * pictures.length)]}
          fit="cover"
          a11yTitle={"Cover picture"}
          className={"tint"}
          loading={"lazy"}
        />

        <Box
          align="center"
          alignContent="center"
          alignSelf="center"
          style={{ position: "absolute", bottom: "7em" }}
          direction={"column"}
          animation="pulse"
        >
          <Anchor
            size={"small"}
            color="brand"
            href={size === "small" ? "#scrollhere" : "#mainHeading"}
            label={t.scroll_down[l].toUpperCase()}
          />
          <Anchor
            size="small"
            color={"brand"}
            href={size === "small" ? "#scrollhere" : "#mainHeading"}
            icon={<I.Down />}
          />
        </Box>
      </Box>
      <Box
        direction={"column"}
        alignContent="center"
        align="center"
        fill="horizontal"
        style={{ minWidth: "300px" }}
      >
        <Text
          margin={"medium"}
          textAlign="center"
          weight="bold"
          size="xxlarge"
          color={"brand"}
        >
          {t.Hero_Image_Text[l]}
        </Text>
        <Box direction="row">
          <Anchor size={"small"} color="brand" label={t.Hero_Button1_Text[l]} />
          <Anchor disabled size="large">
            <pre> </pre>
          </Anchor>
          <Anchor size={"small"} color="brand" label={t.Hero_Button2_Text[l]} />
        </Box>
      </Box>
    </Stack>
  );
};

export default HeroImage;
