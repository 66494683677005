export type supported_languages = "sv" | "en";
const getLanguage = (): supported_languages => {
  const browser_language = window.navigator.language;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const avaiable_languages = window.navigator.languages.map((l, i) => {
    return { language: i, name: l };
  });

  switch (browser_language) {
    // case "en" : return "en";
    // case "en-US": return "en";
    // case "en-EG": return "en";
    // case "en-AU": return "en";
    // case "en-GB": return "en";
    // case "en-CA": return "en";
    // case "en-NZ": return "en";
    // case "en-IE": return "en";
    // case "en-ZA": return "en";
    // case "en-JM": return "en";
    // case "en-BZ": return "en";
    // case "en-TT": return "en";
    default:
      return "sv";
  }
  // en-EG", "en-AU", "en-GB", "en-CA", "en-NZ", "en-IE", "en-ZA", "en-JM","en-BZ", "en-TT" common indicator en
  //sv
  //lt
};

export default getLanguage;
