import {
  Box,
  Button,
  Form,
  FormField,
  Select,
  TextArea,
  TextInput,
  Heading,
  Spinner,
  Text,
  Avatar,
  ResponsiveContext,
} from "grommet";
import { useCallback, useContext, useEffect, useState } from "react";
import { CountryCode, parsePhoneNumber } from "libphonenumber-js";
import { servisesList as sl } from "../helpers/servicesList";
import axios from "axios";
import * as I from "grommet-icons";
import { LanguageContext } from "../App";
import { translations as t } from "../translates/translations";
import { Link, useLocation } from "react-router-dom";
import { scrollUp } from "../helpers/scrollUp";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

export interface FormData {
  name: string;
  surname: string;
  email: string;
  number: string;
  adress: string;
  text: string;
  choose: string;
  subject: string;
  sub_subject?: string;
}

export interface ContactFormDataProps {
  origin?: string;
}

const ContactForm = (p: ContactFormDataProps) => {
  useEffect(() => {
    let meta = document.getElementById("indexing");
    meta?.setAttribute("name", "robots");
    meta?.setAttribute("content", "noindex");

    return () => {
      meta?.removeAttribute("name");
      meta?.removeAttribute("content");
    };
  }, []);

  const l = useContext(LanguageContext);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [status, setStatus] = useState<string>(t.Submit[l]);
  const [country, setCountry] = useState<CountryCode | undefined>(undefined);
  const subject_list = [
    t.Payments[l],
    t.Bookings[l],
    t.Cleaning[l],
    t.Something_Else[l],
  ];
  const search = useLocation().search;
  const spec = new URLSearchParams(search).get("spec");

  const [subject, setSubject] = useState<undefined | string>(undefined);
  const [sub_subject, set_subSubject] = useState<undefined | string>(undefined);
  const formFieldContentProps = { pad: "xsmall", basis: "none", border: false };
  const size = useContext(ResponsiveContext);

  const handleSubject = (e: any) => {
    setSubject(e.value);
  };

  const handle_subSubject = (e: any) => {
    set_subSubject(e.value);
  };

  const services_list = () => {
    let list: string[] = [];
    sl(l, "small").forEach((service) => list.push(service.name));
    return list;
  };

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      alert(
        "Google recaptcha är inte tillgänglig! Försök igen om en stund. Eller kontakta oss på support@stadkamrater.se"
      );
      return false;
    }

    const token = await executeRecaptcha("yourAction");
    if (token.length > 0) {
      return true;
    }
    return false;
  }, [executeRecaptcha]);

  const handleSubmit = async (e: any) => {
    const answer = await handleReCaptchaVerify();

    if (answer === false) {
      return alert("Google reCaptcha misslyckades ! Är du en robot?");
    }
    e.preventDefault();
    setStatus(t.Sending[l]);

    let {
      name,
      surname,
      email,
      number,
      adress,
      text,
      choose,
      subject,
      sub_subject,
    } = e.value as FormData;

    switch (choose) {
      case t.Mobile_Landline[l]:
        choose = "call";
        break;
      case t.SMS[l]:
        choose = "sms";
        break;
      default:
        choose = "email";
        break;
    }
    

    if (number.charAt(0) === "0") {
      number = "+46" + number.slice(1);
    }
    const _number = parsePhoneNumber(number);

    let details = {
      name: name,
      surname: surname,
      mail: email,
      number: _number.formatInternational(),
      country_number: _number.country,
      adress: adress,
      text: text,
      choose: choose,
      subject: subject,
      sub_subject: sub_subject,
      secret: process.env.REACT_APP_API_SECRET as string,
    };

    try {
      const response = await axios.post(
        window.location.origin + "/faasmail/email/send", // process.env.REACT_APP_API_URL as string,
        details,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + process.env.REACT_APP_API_BEARER,
          },
        }
      );

      if (response.status >= 200 && response.status < 300) {
        setStatus(t.Sent[l]);
      }
    } catch (error) {
      setStatus("Försök senare !");
    }

    // let result = await response.json();
    // alert(result.status);
  };

  const checkIfValidString = (s: string) => {
    if (/\d/.test(s)) {
      return t.CANT_CONTAIN_NUMBERS[l];
    }
    if (s.length > 100) {
      return t.TOO_LONG[l];
    }
    return;
  };

  const checkIfValidEmail = (s: string) => {
    if (
      !/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g.test(
        s
      )
    ) {
      return t.EMAIL_IS_NOT_VALID[l];
    }
  };

  const checkIfPhoneNumber = (s: string) => {
    try {
      if (s.charAt(0) === "0") {
        s = "+46" + s.slice(1);
      }

      const _country = parsePhoneNumber(s).country;
      setCountry(_country);
    } catch (error) {
      switch ((error as any).message) {
        case "TOO_SHORT":
          return t.NUMBERS_MISSING[l];
        case "INVALID_COUNTRY":
          return t.WRONG_COUNTRY_CODE[l];
        default:
          return t.INVALID_NUMBER[l];
      }
    }
  };

  useEffect(() => {
    scrollUp();
    if (spec !== null) {
      setSubject(t.Cleaning[l]);
      set_subSubject(spec);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const mobileView = (
    <Box align="center" pad={{ bottom: "5em" }}>
      <Heading textAlign="center">
        Fyll i formuläret vad din fråga gäller så återkommer vi så fort vi kan !
      </Heading>
      <Form
        autoComplete="on"
        messages={{ invalid: t.INVALID[l], required: t.REQUIRED[l] }}
        validate="change"
        onSubmit={handleSubmit}
      >
        <FormField
          name="name"
          htmlFor="name_input"
          label={t.Name[l]}
          validate={checkIfValidString}
          contentProps={formFieldContentProps}
          required
        >
          <Box>
            <TextInput
              icon={<I.ContactInfo />}
              name="name"
              id="name_input"
              placeholder={t.Name[l]}
              autoComplete="given-name"
              size="small"
            />
          </Box>
        </FormField>

        <FormField
          name="surname"
          htmlFor="surname_input"
          label={t.Surname[l]}
          validate={checkIfValidString}
          contentProps={formFieldContentProps}
          required
        >
          <Box>
            <TextInput
              icon={<I.ContactInfo />}
              name="surname"
              id="surname_input"
              placeholder={t.Surname[l]}
              autoComplete="family-name"
              size="small"
            />
          </Box>
        </FormField>

        <FormField
          name="email"
          htmlFor="email_input"
          label={t.Email[l]}
          validate={checkIfValidEmail}
          contentProps={formFieldContentProps}
          required
        >
          <Box>
            <TextInput
              icon={<I.Mail />}
              name="email"
              id="email_input"
              placeholder={"email@email.se"}
              autoComplete="email"
              size="small"
            />
          </Box>
        </FormField>

        <FormField
          name="number"
          htmlFor="number_input"
          label={t.Number[l]}
          validate={checkIfPhoneNumber}
          contentProps={formFieldContentProps}
          required
        >
          <Box direction="row" align="center" style={{ border: "none" }}>
            {country && (
              <Text textAlign="end" style={{ paddingRight: "1em" }}>
                {country}
              </Text>
            )}
            <TextInput
              icon={<I.PhoneVertical />}
              name="number"
              id="number_input"
              placeholder={"+46XXXXXXXXX"}
              autoComplete="tel"
              size="small"
            />
          </Box>
        </FormField>

        <FormField
          name="adress"
          htmlFor="adress_input"
          label={t.Adress[l]}
          contentProps={formFieldContentProps}
          required
        >
          <Box>
            <TextArea
              name="adress"
              id="adress_input"
              placeholder={t.Fake_Adress_Placeholder[l]}
              autoComplete="street-address"
              size="small"
            />
          </Box>
        </FormField>

        <FormField
          name="subject"
          htmlFor="subject_input"
          label={t.What_Subject_Your_Question_Regards_To[l]}
          contentProps={formFieldContentProps}
          required
        >
          <Box>
            <Select
              icon={<I.Down />}
              name="subject"
              id="subject_input"
              options={subject_list}
              size="small"
              value={subject}
              onChange={handleSubject}
            />
          </Box>
        </FormField>

        {subject === t.Cleaning[l] && (
          <Box animation={"slideDown"}>
            <FormField
              name="sub_subject"
              htmlFor="sub_subject_input"
              label={t.Please_Specify[l]}
              contentProps={formFieldContentProps}
              required
            >
              <Box>
                <Select
                  icon={<I.Down />}
                  name="sub_subject"
                  id="sub_subject_input"
                  options={services_list()}
                  size="small"
                  value={sub_subject}
                  onChange={handle_subSubject}
                />
              </Box>
            </FormField>
          </Box>
        )}

        <FormField
          name="text"
          htmlFor="text_input"
          label={t.Please_Enter_Your_Inquiry[l]}
          contentProps={formFieldContentProps}
          required
        >
          <Box>
            <TextArea
              name="text"
              id="text_input"
              placeholder={t.Please_Enter_Your_Inquiry[l]}
              size="small"
              style={{ minHeight: "10em" }}
            />
          </Box>
        </FormField>

        <FormField
          name="choose"
          htmlFor="choose_input"
          label={t.I_Prefer_To_Be_Contacted_By[l] + ":"}
          contentProps={formFieldContentProps}
          required
        >
          <Box>
            <Select
              icon={<I.Down />}
              name="choose"
              id="choose_input"
              options={[t.Mobile_Landline[l], t.Email[l],t.SMS[l]]}
              size="small"
            />
          </Box>
        </FormField>

        <Box direction="row" gap="xxsmall" pad={{ top: "2em" }}>
          <Button
            primary
            disabled={status === t.Sent[l] && true}
            icon={status === t.Sending[l] ? <Spinner /> : <I.Send />}
            color={"special"}
            type="submit"
            label={status}
          />
          <Button
            primary
            icon={<I.Revert />}
            color={"special"}
            type="reset"
            label={t.Reset[l]}
            disabled={status === t.Sent[l] && true}
          />
        </Box>
        {status === t.Sent[l] && (
          <Box animation={"zoomOut"} align={"center"} margin={"large"}>
            <Avatar
              size={"medium"}
              src={"https://www.w3schools.com/howto/img_avatar2.png"}
            ></Avatar>
            <Text>{t.we_received_your_message[l]}</Text>
          </Box>
        )}

        {status === t.Sent[l] && (
          <Box animation={"zoomIn"} pad={"small"}>
            <Link to="/" style={{ width: "100%", textAlign:"center" }}>
              <Button
                size="large"
                primary
                label={t.Home[l]}
                color={"special"}
                onClick={() => scrollUp()}
              ></Button>
            </Link>
          </Box>
        )}
      </Form>
    </Box>
  );

  const desktopView = (
    <Box direction="row">
      <Box pad={"large"} background={"special"} style={{ maxWidth: "30%" }}>
        {status !== t.Sent[l] && (
          <>
            <Heading level={2} textAlign="center">
              Fyll i formuläret vad din fråga gäller så återkommer vi så fort vi
              kan !
            </Heading>
            <Avatar
              size={"5xl"}
              src={"https://www.w3schools.com/howto/img_avatar2.png"}
              alignSelf={"center"}
            ></Avatar>
          </>
        )}

        {status === t.Sent[l] && (
          <Box animation={"zoomOut"} margin={{ top: "100%" }}>
            <Avatar
              size={"xlarge"}
              src={"https://www.w3schools.com/howto/img_avatar2.png"}
              alignSelf={"center"}
            ></Avatar>
            <Text textAlign="center">{t.we_received_your_message[l]}</Text>
          </Box>
        )}
      </Box>
      <Box
        pad={{
          bottom: "5em",
          left: "5em",
          right: size === "large" ? "15em" : "2em",
        }}
        fill
      >
        <Form
          autoComplete="on"
          messages={{ invalid: t.INVALID[l], required: t.REQUIRED[l] }}
          validate="change"
          onSubmit={handleSubmit}
        >
          <Box direction="row">
            <FormField
              name="name"
              htmlFor="name_input"
              label={
                <Text weight={800} size={"small"}>
                  {t.Name[l]}
                </Text>
              }
              validate={checkIfValidString}
              contentProps={formFieldContentProps}
              required
            >
              <Box>
                <TextInput
                  icon={<I.ContactInfo />}
                  name="name"
                  id="name_input"
                  placeholder={t.Name[l]}
                  autoComplete="given-name"
                  size="small"
                />
              </Box>
            </FormField>

            <FormField
              name="surname"
              htmlFor="surname_input"
              label={
                <Text weight={800} size={"small"}>
                  {t.Surname[l]}
                </Text>
              }
              validate={checkIfValidString}
              contentProps={formFieldContentProps}
              required
            >
              <Box>
                <TextInput
                  icon={<I.ContactInfo />}
                  name="surname"
                  id="surname_input"
                  placeholder={t.Surname[l]}
                  autoComplete="family-name"
                  size="small"
                />
              </Box>
            </FormField>
          </Box>

          <FormField
            name="email"
            htmlFor="email_input"
            label={
              <Text weight={800} size={"small"}>
                {t.Email[l]}
              </Text>
            }
            validate={checkIfValidEmail}
            contentProps={formFieldContentProps}
            required
          >
            <Box>
              <TextInput
                icon={<I.Mail />}
                name="email"
                id="email_input"
                placeholder={"email@email.se"}
                autoComplete="email"
                size="small"
              />
            </Box>
          </FormField>

          <FormField
            name="number"
            htmlFor="number_input"
            label={
              <Text weight={800} size={"small"}>
                {t.Number[l]}
              </Text>
            }
            validate={checkIfPhoneNumber}
            contentProps={formFieldContentProps}
            required
          >
            <Box direction="row" align="center" style={{ border: "none" }}>
              {country && (
                <Text textAlign="end" style={{ paddingRight: "1em" }}>
                  {country}
                </Text>
              )}
              <TextInput
                icon={<I.PhoneVertical />}
                name="number"
                id="number_input"
                placeholder={"+46XXXXXXXXX"}
                autoComplete="tel"
                size="small"
              />
            </Box>
          </FormField>

          <FormField
            name="adress"
            htmlFor="adress_input"
            label={
              <Text weight={800} size={"small"}>
                {t.Adress[l]}
              </Text>
            }
            contentProps={formFieldContentProps}
            required
          >
            <Box>
              <TextArea
                name="adress"
                id="adress_input"
                placeholder={t.Fake_Adress_Placeholder[l]}
                autoComplete="street-address"
                size="small"
              />
            </Box>
          </FormField>

          <Box direction="row">
            <FormField
              name="subject"
              htmlFor="subject_input"
              label={
                <Text weight={800} size={"small"}>
                  {t.What_Subject_Your_Question_Regards_To[l]}
                </Text>
              }
              contentProps={formFieldContentProps}
              required
            >
              <Box>
                <Select
                  icon={<I.Down />}
                  name="subject"
                  id="subject_input"
                  options={subject_list}
                  size="small"
                  value={subject}
                  onChange={handleSubject}
                />
              </Box>
            </FormField>

            <Box animation={"slideRight"}>
              <FormField
                name="sub_subject"
                htmlFor="sub_subject_input"
                label={
                  <Text weight={800} size={"small"}>
                    {t.Please_Specify[l]}
                  </Text>
                }
                contentProps={formFieldContentProps}
                required={subject === t.Cleaning[l] ? true : false}
                className={subject === t.Cleaning[l] ? "in" : "out"}
              >
                <Box>
                  <Select
                    icon={<I.Down />}
                    name="sub_subject"
                    id="sub_subject_input"
                    options={services_list()}
                    size="small"
                    value={sub_subject}
                    onChange={handle_subSubject}
                  />
                </Box>
              </FormField>
            </Box>
          </Box>

          <FormField
            name="text"
            htmlFor="text_input"
            label={
              <Text weight={800} size={"small"}>
                {t.Please_Enter_Your_Inquiry[l]}
              </Text>
            }
            contentProps={formFieldContentProps}
            required
          >
            <Box>
              <TextArea
                name="text"
                id="text_input"
                placeholder={t.Please_Enter_Your_Inquiry[l]}
                size="small"
                style={{ minHeight: "10em" }}
              />
            </Box>
          </FormField>

          <FormField
            name="choose"
            htmlFor="choose_input"
            label={
              <Text weight={800} size={"small"}>
                {t.I_Prefer_To_Be_Contacted_By[l] + ":"}
              </Text>
            }
            contentProps={formFieldContentProps}
            required
          >
            <Box style={{ maxWidth: "50%" }}>
              <Select
                icon={<I.Down />}
                name="choose"
                id="choose_input"
                options={[t.Mobile_Landline[l], t.Email[l],t.SMS[l]]}
                size="small"
              />
            </Box>
          </FormField>

          <Box align="center">
            <Box
              direction="row"
              gap="xxsmall"
              pad={{ top: "2em" }}
              align="center"
            >
              <Button
                primary
                disabled={status === t.Sent[l] && true}
                icon={status === t.Sending[l] ? <Spinner /> : <I.Send />}
                color={"special"}
                type="submit"
                label={status}
              />
              <Button
                primary
                icon={<I.Revert />}
                color={"special"}
                type="reset"
                label={t.Reset[l]}
                disabled={status === t.Sent[l] && true}
                onClick={() => scrollUp()}
              />
            </Box>
          </Box>

          {status === t.Sent[l] && (
            <Box animation={"zoomIn"} pad={"small"}>
              <Link to="/" style={{ width: "100%" }}>
                <Button
                  fill
                  primary
                  label={t.Home[l]}
                  color={"special"}
                  onClick={() => scrollUp()}
                ></Button>
              </Link>
            </Box>
          )}
        </Form>
      </Box>
    </Box>
  );

  return (
    <Box background={"brand-transparent20"}>
      <Box style={{ minHeight: "60px" }} background={{ color: "brand" }}></Box>

      {size === "small" ? mobileView : desktopView}
    </Box>
  );
};

export default ContactForm;
