import {
  Anchor,
  Box,
  Button,
  Heading,
  Paragraph,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
  Image,
  Text,
} from "grommet";
import { useContext } from "react";
import { LanguageContext } from "../../App";
import { servisesList } from "../../helpers/servicesList";
import { translations as t } from "../../translates/translations";
import * as I from "grommet-icons";
import load_pic from "../../helpers/imageLoader";
import { useDocumentTitle } from "../hooks/useDocumentTitle";
import setMetaTagForCanonicalUrl from "../../helpers/setMetaTagForCanonicalUrl";
import { Link } from "react-router-dom";
import guarantee from "../../img/grnt.png";

export interface PriceListPageProps {
  size: string;
}

const PricelistPage = (p: PriceListPageProps) => {
  setMetaTagForCanonicalUrl();
  const { size } = p;
  const l = useContext(LanguageContext);
  useDocumentTitle(t.PriceList[l]);
  const services = servisesList(l, size);

  return (
    <>
      <Box style={{ minHeight: "60px" }} background={{ color: "brand" }}></Box>
      <Box
        height={"small"}
        align="center"
        background={{
          color: "brand",
          dark: false,
          position: "bottom",
          repeat: "no-repeat",
          size: "cover",
          image: `url(${load_pic("time_money", l)})`,
        }}
      >
        <Box background={"tint-transparent40"} fill>
          <Heading color={"brand"} textAlign="center" fill responsive={true}>
            {t.PriceList[l]}
          </Heading>
        </Box>
      </Box>

      <Box
        align="center"
        pad={{ bottom: "5em" }}
        margin={{ top: "2em", bottom: "2em" }}
      >
        <Table>
          <TableHeader>
            <TableRow>
              <TableCell scope="col" border="bottom"></TableCell>
              <TableCell scope="col" border="bottom">
                {`${t.SERVICE_NAME[l]}`}
              </TableCell>
              <TableCell scope="col" border="bottom">
                {`${t.PRICE[l]}`}
              </TableCell>
            </TableRow>
          </TableHeader>
          <TableBody>
            {services.map((s) => {
              return (
                <TableRow key={s.name}>
                  <TableCell>
                    <I.ClearOption color="status-ok" />
                  </TableCell>
                  <TableCell scope="row">
                    <Box direction="column">
                      <Text weight={800}>{s.name}</Text>
                    </Box>
                  </TableCell>
                  <TableCell style={{ lineHeight: "2px" }}>
                    <Text size="xsmall">
                      {`${s.content.price_list[0]} ${s.content.price_list[1]}`}{" "}
                      <Anchor
                        color={"status-neutral"}
                        href={`/services-list/${s.name}`}
                      >
                        Mer om {s.name}
                      </Anchor>
                    </Text>
                  </TableCell>
                </TableRow>
                
                
              );
            })}
          </TableBody>
        </Table>
      </Box>
      <Box align="center">
      <Heading textAlign="center"> {`${t.HappyCustomerGuaranteeAlwaysIncluded[l]}`}</Heading>
      </Box>

      <Box>
      <Image
              fit={"contain"}
              src={guarantee}
              style={{
                backgroundColor: "#transparent100",
                maxHeight: "300px"
              }}
            />
      </Box>
      <Paragraph></Paragraph>
      <Box gridArea="big-text" animation={"fadeIn"} align={"center"}>
        <Link to="/contact_form">
          <Button
            color={"special"}
            size={"large"}
            icon={<I.UserExpert />}
            primary
            style={{ border: "0px" }}
            // fill={"horizontal"}
            hoverIndicator
            label={(t.contact_form as any)[l]}
          />
        </Link>
      </Box>
      <Paragraph></Paragraph>
    </>
  );
};

export default PricelistPage;
