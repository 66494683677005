import l_window_clean from "../../img/large/window_clean.jpg";
import l_mop_office from "../../img/large/mop_office.jpg";
import l_garden_work from "../../img/large/garden_work.jpg";
import l_move_clean from "../../img/large/move_clean.jpg";
import l_home_clean from "../../img/large/home_clean.jpg";
import l_big_clean from "../../img/large/big_clean.jpg";

import m_window_clean from "../../img/medium/window_clean.jpg";
import m_mop_office from "../../img/medium/mop_office.jpg";
import m_garden_work from "../../img/medium/garden_work.jpg";
import m_move_clean from "../../img/medium/move_clean.jpg";
import m_home_clean from "../../img/medium/home_clean.jpg";
import m_big_clean from "../../img/medium/big_clean.jpg";

import s_window_clean from "../../img/small/window_clean.jpg";
import s_mop_office from "../../img/small/mop_office.jpg";
import s_garden_work from "../../img/small/garden_work.jpg";
import s_move_clean from "../../img/small/move_clean.jpg";
import s_home_clean from "../../img/small/home_clean.jpg";
import s_big_clean from "../../img/small/big_clean.jpg";

import s_sink_image from "../../img/small/cleansink.jpg";
import m_sink_image from "../../img/medium/cleansink.jpg";
import l_sink_image from "../../img/large/cleansink.jpg";

import s_mirror from "../../img/small/mirror_clean.jpg";
import m_mirror from "../../img/medium/mirror_clean.jpg";
import l_mirror from "../../img/large/mirror_clean.jpg";

import s_mop from "../../img/small/mop.jpg";
import m_mop from "../../img/medium/mop.jpg";
import l_mop from "../../img/large/mop.jpg";

import s_hero_pic from "../../img/small/pic.jpg";
import m_hero_pic from "../../img/medium/pic.jpg";
import l_hero_pic from "../../img/large/pic.jpg";

import s_table_top from "../../img/small/table_top.jpg";
import m_table_top from "../../img/medium/table_top.jpg";
import l_table_top from "../../img/large/table_top.jpg";

import s_time_money from "../../img/small/time_money.jpg";
import m_time_money from "../../img/medium/time_money.jpg";
import l_time_money from "../../img/large/time_money.jpg";

import s_mop_mirror from "../../img/small/mop_mirror.jpg";
import m_mop_mirror from "../../img/medium/mop_mirror.jpg";
import l_mop_mirror from "../../img/large/mop_mirror.jpg";

export const image_db: { [key: string]: string } = {
  s_window_clean,
  m_window_clean,
  l_window_clean,
  s_mop_office,
  m_mop_office,
  l_mop_office,
  s_garden_work,
  m_garden_work,
  l_garden_work,
  s_move_clean,
  m_move_clean,
  l_move_clean,
  s_home_clean,
  m_home_clean,
  l_home_clean,
  s_big_clean,
  m_big_clean,
  l_big_clean,
  s_sink_image,
  m_sink_image,
  l_sink_image,
  s_mirror,
  m_mirror,
  l_mirror,
  s_mop,
  m_mop,
  l_mop,
  s_hero_pic,
  m_hero_pic,
  l_hero_pic,
  s_table_top,
  m_table_top,
  l_table_top,
  s_time_money,
  m_time_money,
  l_time_money,
  s_mop_mirror,
  m_mop_mirror,
  l_mop_mirror,
};
