import { Accordion, AccordionPanel, Box, Button, Heading, Text } from "grommet";
import { useContext } from "react";
import { LanguageContext } from "../../App";
import { faq_list as fl } from "../../helpers/faq_list";
import { translations as t } from "../../translates/translations";
import logo from "../../img/logo4o.png";
import percentToHex from "../../helpers/percentToHex";
import { Link } from "react-router-dom";
import * as I from "grommet-icons";
import { useDocumentTitle } from "../hooks/useDocumentTitle";
import setMetaTagForCanonicalUrl from "../../helpers/setMetaTagForCanonicalUrl";

const FAQPage = () => {
  setMetaTagForCanonicalUrl();
  const l = useContext(LanguageContext);
  const faq_list = fl(l);
  useDocumentTitle(t.faq[l]);

  return (
    <>
      <Box style={{ minHeight: "60px" }} background={{ color: "brand" }}></Box>
      <Box
        align="center"
        background={{
          color: "brand",
          opacity: 0.8,
          position: "bottom",
          repeat: "no-repeat",
          size: "fit",
          image: `url(${logo})`,
        }}
      >
        <Heading>{t.faq[l]}</Heading>

        <Box alignSelf="start" fill>
          <Accordion multiple={true} margin={"medium"}>
            {faq_list.map((q) => (
              <AccordionPanel
                key={`${q.id}`}
                label={`${q.id}. ${q.question}`}
                style={{ backgroundColor: "#111111" + percentToHex(10) }}
              >
                <Box background={"icons"} pad={"medium"} fill>
                  <Text size="small">{q.answer}</Text>
                </Box>
              </AccordionPanel>
            ))}
          </Accordion>
        </Box>
        <Box>
          {" "}
          <Heading fill textAlign="center" responsive={true} size="small">
            {t.Contact_us_if_you_havy_any_questions[l]}
            <Link to={`/contact_form`}>
              <Button
                margin={"xsmall"}
                size={"large"}
                icon={<I.UserExpert />}
                primary
                style={{ border: "0px" }}
                hoverIndicator
                label={t.contact_form[l]}
              />
            </Link>
          </Heading>
        </Box>
      </Box>
    </>
  );
};

export default FAQPage;
