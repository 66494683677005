import { translations as t } from "../translates/translations";
import { Link } from "react-router-dom";
import { Box, Button, Clock } from "grommet";
import { LanguageContext } from "../App";
import { useContext } from "react";
import { is_active_path } from "../helpers/getCurrentPath";
import { scrollUp } from "../helpers/scrollUp";
import logo from "../img/logo4o.png";
import se from "../img/flags/se.svg";
import gb from "../img/flags/gb.svg";
import * as I from "grommet-icons";

interface MenuProps {
  size: string;
  toggleMenu: () => void;
  menuIsOpen: boolean;
}

const Menu = (props: MenuProps) => {
  const l = useContext(LanguageContext);
  const { size } = props;

  return (
    <>
      {size !== "small" ? null : (
        <Box
          background={{
            color: `brand-transparent92`,
            dark: true,
            position: "top",
            repeat: "no-repeat",
            size: size === "small" ? "contain" : "70vh auto",
            image: `url(${logo})`,
          }}
          direction="column"
          align="center"
          className={props.menuIsOpen ? "showMenu" : "hideMenu"}
          style={{
            zIndex: "999",
            top: "60px",
            paddingTop: "4em",
            overflow: "scroll",
          }}
        >
          <Clock type="digital" margin={{ bottom: "1em" }} />

          <Box>
            <Box alignSelf="start">
              <Link style={{ width: "100%" }} onClick={props.toggleMenu} to="/">
                <Button
                  margin={"xsmall"}
                  active={is_active_path("/", l)}
                  size={"large"}
                  style={{ border: "0px", fontWeight: 800, fontSize: "2em" }}
                  fill={"horizontal"}
                  hoverIndicator
                  label={t.Home[l]}
                  onClick={scrollUp}
                  icon={<I.Home size="medium" color={"bright-white-always"} />}
                />
              </Link>
            </Box>

            <Box alignSelf="start">
              <Link
                style={{ width: "100%" }}
                onClick={props.toggleMenu}
                to="/services-list"
              >
                <Button
                  margin={"xsmall"}
                  active={is_active_path("/services-list", l)}
                  size={"large"}
                  style={{ border: "0px", fontWeight: 800, fontSize: "2em" }}
                  fill={"horizontal"}
                  hoverIndicator
                  label={t.Services[l]}
                  onClick={scrollUp}
                  icon={
                    <I.BusinessService
                      size="medium"
                      color={"bright-white-always"}
                    />
                  }
                />
              </Link>
            </Box>

            <Box alignSelf="start">
              {" "}
              <Link
                style={{ width: "100%" }}
                onClick={props.toggleMenu}
                to="/pricelist"
              >
                <Button
                  margin={"xsmall"}
                  active={is_active_path("/pricelist", l)}
                  size={"large"}
                  style={{ border: "0px", fontWeight: 800, fontSize: "2em" }}
                  fill={"horizontal"}
                  hoverIndicator
                  label={(t.pricelist as any)[l]}
                  onClick={scrollUp}
                  icon={<I.Money size="medium" color={"bright-white-always"} />}
                />
              </Link>
            </Box>

            <Box alignSelf="start">
              {" "}
              <Link
                style={{ width: "100%" }}
                onClick={props.toggleMenu}
                to="/faq"
              >
                <Button
                  margin={"xsmall"}
                  active={is_active_path("/faq", l)}
                  size={"large"}
                  style={{ border: "0px", fontWeight: 800, fontSize: "2em" }}
                  fill={"horizontal"}
                  hoverIndicator
                  label={t.faq[l]}
                  onClick={scrollUp}
                  icon={
                    <I.CircleQuestion
                      size="medium"
                      color={"bright-white-always"}
                    />
                  }
                />
              </Link>
            </Box>

            {/* <Box alignSelf="start">
              {" "}
              <Link
                style={{ width: "100%" }}
                onClick={props.toggleMenu}
                to="/gallery"
              >
                <Button
                  margin={"xsmall"}
                  active={is_active_path("/gallery", l)}
                  size={"large"}
                  style={{ border: "0px", fontWeight: 800, fontSize: "2em" }}
                  fill={"horizontal"}
                  hoverIndicator
                  label={(t.pic_gallery as any)[l]}
                  onClick={scrollUp}
                  icon={<I.Image size="medium" color={"bright-white-always"} />}
                />
              </Link>
            </Box> */}

            <Box alignSelf="start">
              {" "}
              <Link
                style={{ width: "100%" }}
                onClick={props.toggleMenu}
                to="/policy"
              >
                <Button
                  margin={"xsmall"}
                  active={is_active_path("/policy", l)}
                  size={"large"}
                  style={{ border: "0px", fontWeight: 800, fontSize: "2em" }}
                  fill={"horizontal"}
                  hoverIndicator
                  label={(t.Policy as any)[l]}
                  onClick={scrollUp}
                  icon={
                    <I.License size="medium" color={"bright-white-always"} />
                  }
                />
              </Link>
            </Box>

            <Box alignSelf="start">
              <Link
                style={{ width: "100%" }}
                onClick={props.toggleMenu}
                to="/contacts"
              >
                <Button
                  margin={"xsmall"}
                  active={is_active_path("/contacts", l)}
                  size={"large"}
                  style={{ border: "0px", fontWeight: 800, fontSize: "2em" }}
                  fill={"horizontal"}
                  hoverIndicator
                  label={(t.Contacts as any)[l]}
                  onClick={scrollUp}
                  icon={
                    <I.PhoneVertical
                      size="medium"
                      color={"bright-white-always"}
                    />
                  }
                />
              </Link>
            </Box>
          </Box>

          <Box
            direction={"column"}
            align="center"
            alignSelf="center"
            alignContent="center"
          >
            {/* <Box direction="row" style={{ minHeight: "1.5em" }}>
            <Button
              disabled
              style={{
                backgroundImage: `url(${se})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                border: "none",
                borderRadius: "50px",
              }}
            />
            <Button
              disabled
              style={{
                backgroundImage: `url(${gb})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                border: "none",
                borderRadius: "50px",
              }}
            />
          </Box> */}
          </Box>
        </Box>
      )}
    </>
  );
};

export default Menu;
