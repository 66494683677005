import { Route, Switch } from "react-router-dom";
import ContactForm from "./ContactForm";
import ContactsPage from "./Pages/ContactsPage";
import FAQPage from "./Pages/FAQPage";
import NotFoundPage from "./Pages/NotFoundPage";
// import PictureGalleryPage from "./Pages/PictureGalleryPage";
import PolicyPage from "./Pages/PolicyPage";
import PricelistPage from "./Pages/PricelistPage";
import ServicesPageList from "./Pages/ServicesPageList";
import StartPage from "./Pages/StartPage";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

interface ContentProps {
  size: string;
}

const Content = ({ size }: ContentProps) => {
  return (
    <>
      <Switch>
        <Route exact path="/">
          <StartPage size={size} />
        </Route>
        <Route exact path="/services-list">
          <ServicesPageList size={size} />
        </Route>
        <Route exact path="/services-list/:spec">
          <ServicesPageList size={size} />
        </Route>
        <Route path="/policy">
          <PolicyPage />
        </Route>
        <Route path="/contacts">
          <ContactsPage size={size} />
        </Route>
        <Route path="/pricelist">
          <PricelistPage size={size} />
        </Route>
        <Route path="/faq">
          <FAQPage />
        </Route>
        {/* <Route path="/gallery">
          <PictureGalleryPage />
        </Route> */}
        <Route path="/contact_form">
          <GoogleReCaptchaProvider
            reCaptchaKey={process.env.REACT_APP_API_GOOGLE as string}
          >
            <ContactForm />
          </GoogleReCaptchaProvider>
        </Route>
        <Route path="*">
          <NotFoundPage />
        </Route>
      </Switch>
    </>
  );
};

export default Content;

/* <Spinner color="blue" message="spinner loaded"/>
<NameValueList align="center">
      
<NameValuePair name={"nav lang"}>
<Text color="text-strong">{window.navigator.language}</Text>
</NameValuePair>

<Text color="text-strong">Amount: {window.navigator.languages.length}</Text>

</NameValueList> 

<List
primaryKey="name"
secondaryKey="percent"
data={lang}
/> */
