import { optionalTable, translations as t } from "../translates/translations";
import { supported_languages } from "./getLanguage";
import load_pic from "./imageLoader";

export type servicesListType = {
  name: string;
  content: servicesListContentType;
  image?: any;
};

export type servicesListContentType = {
  title: string;
  paragraph: string;
  header: string;
  lists: ContentListsType[];
  asterix: string;
  price_title: string;
  price_list: string[];
  optional_asterix?: string;
  optional_text?: string;
  guarantee: string[];
  optional_table: optionalTable;
};

export type ContentListsType = {
  name: string;
  items: string[];
};

export const servisesList = (l: supported_languages, size: string) => {
  return [
    {
      name: t.HomeCleaning[l],
      image: load_pic("home_clean", size),
      content: {
        title: t.home_cleaning_service_title[l],
        paragraph: t.home_cleaning_service_paragraph[l],
        header: t.home_cleaning_service_header[l],
        lists: t.home_cleaning_service_lists[l],
        asterix: t.home_cleaning_service_asterix[l],
        price_title: t.home_cleaning_service_price_title[l],
        price_list: t.home_cleaning_service_price_list[l],
        optional_asterix: t.home_cleaning_service_optional_asterix[l],
        optional_text: t.home_cleaning_service_optional_text[l],
        guarantee: t.home_cleaning_service_guarantee[l],
        optional_table: t.home_cleaning_service_optional_table[l],
      },
    },
    {
      name: t.BigCleaning[l],
      image: load_pic("big_clean", size),
      content: {
        title: t.big_cleaning_service_title[l],
        paragraph: t.big_cleaning_service_paragraph[l],
        header: t.big_cleaning_service_header[l],
        lists: t.big_cleaning_service_lists[l],
        asterix: t.big_cleaning_service_asterix[l],
        price_title: t.big_cleaning_service_price_title[l],
        price_list: t.big_cleaning_service_price_list[l],
        optional_asterix: t.big_cleaning_service_optional_asterix[l],
        optional_text: t.big_cleaning_service_optional_text[l],
        guarantee: t.big_cleaning_service_guarantee[l],
        optional_table: t.big_cleaning_service_optional_table[l],
      },
    },
    {
      name: t.WorkplaceCleaning[l],
      image: load_pic("mop_office", size),
      content: {
        title: t.workplace_cleaning_service_title[l],
        paragraph: t.workplace_cleaning_service_paragraph[l],
        header: t.workplace_cleaning_service_header[l],
        lists: t.workplace_cleaning_service_lists[l],
        asterix: t.workplace_cleaning_service_asterix[l],
        price_title: t.workplace_cleaning_service_price_title[l],
        price_list: t.workplace_cleaning_service_price_list[l],
        optional_asterix: t.workplace_cleaning_service_optional_asterix[l],
        optional_text: t.workplace_cleaning_service_optional_text[l],
        guarantee: t.workplace_cleaning_service_guarantee[l],
        optional_table: t.workplace_cleaning_service_optional_table[l],
      },
    },
    {
      name: t.WindowCleaning[l],
      image: load_pic("window_clean", size),
      content: {
        title: t.window_cleaning_service_title[l],
        paragraph: t.window_cleaning_service_paragraph[l],
        header: t.window_cleaning_service_header[l],
        lists: t.window_cleaning_service_lists[l],
        asterix: t.window_cleaning_service_asterix[l],
        price_title: t.window_cleaning_service_price_title[l],
        price_list: t.window_cleaning_service_price_list[l],
        optional_asterix: t.window_cleaning_service_optional_asterix[l],
        optional_text: t.window_cleaning_service_optional_text[l],
        guarantee: t.window_cleaning_service_guarantee[l],
        optional_table: t.window_cleaning_service_optional_table[l],
      },
    },
    {
      name: t.MoveCleaning[l],
      image: load_pic("move_clean", size),
      content: {
        title: t.move_cleaning_service_title[l],
        paragraph: t.move_cleaning_service_paragraph[l],
        header: t.move_cleaning_service_header[l],
        lists: t.move_cleaning_service_lists[l],
        asterix: t.move_cleaning_service_asterix[l],
        price_title: t.move_cleaning_service_price_title[l],
        price_list: t.move_cleaning_service_price_list[l],
        optional_asterix: t.move_cleaning_service_optional_asterix[l],
        optional_text: t.move_cleaning_service_optional_text[l],
        guarantee: t.move_cleaning_service_guarantee[l],
        optional_table: t.move_cleaning_service_optional_table[l],
      },
    },
    {
      name: t.GardenCleaning[l],
      image: load_pic("garden_work", size),
      content: {
        title: t.garden_cleaning_service_title[l],
        paragraph: t.garden_cleaning_service_paragraph[l],
        header: t.garden_cleaning_service_header[l],
        lists: t.garden_cleaning_service_lists[l],
        asterix: t.garden_cleaning_service_asterix[l],
        price_title: t.garden_cleaning_service_price_title[l],
        price_list: t.garden_cleaning_service_price_list[l],
        optional_asterix: t.garden_cleaning_service_optional_asterix[l],
        optional_text: t.garden_cleaning_service_optional_text[l],
        guarantee: t.garden_cleaning_service_guarantee[l],
        optional_table: t.garden_cleaning_service_optional_table[l],
      },
    },
  ] as servicesListType[];
};
