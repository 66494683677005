import { Anchor, Box, Paragraph, Heading, Button } from "grommet";
import { useContext } from "react";
import { LanguageContext } from "../../App";
import { constants as c } from "../../constants/constants";
import setMetaTagForCanonicalUrl from "../../helpers/setMetaTagForCanonicalUrl";
import { translations as t } from "../../translates/translations";
import { useDocumentTitle } from "../hooks/useDocumentTitle";
import { Link } from "react-router-dom";
import * as I from "grommet-icons";

const PolicyPage = () => {
  setMetaTagForCanonicalUrl();
  const l = useContext(LanguageContext);
  useDocumentTitle(t.Policy[l]);
  return (
    <>
      <Box style={{ minHeight: "60px" }} background={{ color: "brand" }}></Box>
      <Box
        animation={"fadeIn"}
        pad={"large"}
        align={"center"}
        background={{ color: "icons" }}
      >
        <Box align={"center"} width="large">
          <Heading
            margin={"small"}
            alignSelf={"center"}
            textAlign={"center"}
            level={2}
          >
            {t.information_about_GDPR_header[l]}
          </Heading>
          <Paragraph margin={"xxsmall"} size={"medium"} textAlign="center" fill>
            {t.information_about_GDPR_paragraph_p1[l]}
            {c.organization_name} {t.information_about_GDPR_paragraph_p2[l]}
          </Paragraph>
          <Paragraph margin={"xxsmall"} size={"medium"} textAlign="center" fill>
            {t.information_about_GDPR_paragraph2_p1[l]}
            {c.email} {t.or[l]} {c.phone_number}.
          </Paragraph>
          <Paragraph></Paragraph>
          <Paragraph></Paragraph>
          <Heading margin={"small"} alignSelf={"center"} level={2}>
            {t.how_do_we_store_data[l]}
          </Heading>
          <Paragraph margin={"xxsmall"} fill>
            {t.vi_have_bokforingen_at[l]} {c.bokforingen_org}
            {`. `}
            {t.vi_have_bokforingen_at_p2[l]}
          </Paragraph>
          <Paragraph>

          </Paragraph>
          <Anchor
            alignSelf={"center"}
            margin={"xxsmall"}
            href={c.bokforingen_org_url}
            label={`www.${c.bokforingen_org_url.split(".")[1]}.com`}
          />
        </Box>
        <Paragraph></Paragraph>
        <Paragraph></Paragraph>
        <Paragraph></Paragraph>
        <Paragraph></Paragraph>
        <Box gridArea="big-text" animation={"fadeIn"} align={"center"}>
        <Link to="/contact_form">
          <Button
            color={"special"}
            size={"large"}
            icon={<I.UserExpert />}
            primary
            style={{ border: "0px" }}
            // fill={"horizontal"}
            hoverIndicator
            label={(t.contact_form as any)[l]}
          />
        </Link>
      </Box>
      <Paragraph></Paragraph>
        <Paragraph></Paragraph>
      </Box>


    </>
  );
};

export default PolicyPage;
