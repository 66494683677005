import {
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CheckBox,
  Heading,
  Image,
  Paragraph,
  Stack,
} from "grommet";
import HeroImage from "../HeroImage";
import { translations as t } from "../../translates/translations";
import { useContext, useEffect, useRef, useState } from "react";
import { LanguageContext } from "../../App";
import load_pic from "../../helpers/imageLoader";
import { Link } from "react-router-dom";
import * as I from "grommet-icons";
import guarantee from "../../img/grnt.png";
import { useDocumentTitle } from "../hooks/useDocumentTitle";
import setMetaTagForCanonicalUrl from "../../helpers/setMetaTagForCanonicalUrl";
import { servisesList as sl } from "../../helpers/servicesList";

interface StartPageProps {
  size: string;
}

const StartPage = (p: StartPageProps) => {
  setMetaTagForCanonicalUrl();
  const { size } = p;
  const l = useContext(LanguageContext);
  const servicesList = sl(l, size);
  const getGridColumnNumber = (size: string) => {
    if (size === "medium") {
      return 2;
    }
    return 3;
  };
  const gridStyle = {
    display: "grid",
    gap: "0rem",
    gridTemplateColumns: `repeat(${getGridColumnNumber(
      size
    )}, minmax(200px, 1fr))`,
  };

  useDocumentTitle("Städfirma | Professionella städtjänster");
  const checkboxRef = useRef<HTMLDivElement>(null);
  const [checkBoxHeight, setChecboxHeight] = useState(0);
  const check_items = [
    t.start_checklist_item_1[l],
    t.start_checklist_item_2[l],
    t.start_checklist_item_3[l],
    t.start_checklist_item_4[l],
    t.start_checklist_item_5[l],
  ];

  useEffect(() => {
    checkboxRef.current && setChecboxHeight(checkboxRef.current.clientHeight);
  }, []);

  return size === "small" ? (
    <>
      <HeroImage size={size} />

      <Box id={"scrollhere"} pad={{ left: "1em", right: "1em" }}>
        <Heading textAlign="center" level={2} id="mainHeading">
          {t.startPageHeading[l]}
        </Heading>
      </Box>

      <Box
        style={{ border: "0px" }}
        direction="column"
        border={{ color: "brand" }}
        pad="medium"
        align="center"
      >
        <Paragraph size={"medium"} textAlign="center">
          {" "}
          {t.startPageParagraph1[l]}
        </Paragraph>
        <Paragraph size={"medium"} textAlign="center">
          {" "}
          {t.startPageParagraph2[l]}
        </Paragraph>
        <Paragraph size={"medium"} textAlign="center">
          {" "}
          {t.startPageParagraph3[l]}
        </Paragraph>
        {/* -------------------------- */}

        <Box align="center" direction="column">
          <Heading textAlign="center" level={1}>
            {t.our_services[l]}
          </Heading>
        </Box>

        <Box align="center">
          {servicesList.map((s,i) => {
            return (
              <Card
              key={i}
                height="small"
                width="medium"
                background={`url(${s.image})`}
                margin={"medium"}
              >
                <Box align="center" background={"tint-transparent40"}>
                  <Box fill>
                    <Link to={`/services-list/${s.name}`}>
                      <Button
                        fill
                        primary
                        icon={<I.More color="special" />}
                        hoverIndicator
                        label={
                          t.read_more_about[l].charAt(0).toUpperCase() +
                          t.read_more_about[l].slice(1)
                        }
                      />
                    </Link>
                  </Box>

                  <CardHeader pad="medium">
                    <Heading level={3} margin="none" color={"brand"}>
                      {s.name}
                    </Heading>
                  </CardHeader>
                </Box>
                <CardBody pad="medium">{}</CardBody>
                <CardFooter pad={{ horizontal: "small" }} background="brand">
                  <Box fill>
                    <Link to={`/contact_form?spec=${s.name}`}>
                      <Button
                        fill
                        primary
                        icon={<I.Contact color="special" />}
                        hoverIndicator
                        label={t.Contact_Us[l]}
                      />
                    </Link>
                  </Box>
                </CardFooter>
              </Card>
            );
          })}
        </Box>

        {/* ---------------------------- */}

        <Heading textAlign="center" level={2}>
          {t.with_us_you_get[l]}
        </Heading>
        <Box pad="medium">
          {check_items.map((item, i) => (
            <CheckBox
              color="background"
              checked={true}
              label={item}
              pad={"xxsmall"}
              key={i}
            />
          ))}
        </Box>

        <Box height={"small"}>
          <Image
            a11yTitle="picture of a guarantee mark"
            fill
            fit={"contain"}
            src={guarantee}
            style={{ backgroundColor: "#transparent100" }}
          />
        </Box>
      </Box>

      <Box gridArea="big-text" animation={"fadeIn"}>
        <Link to="/contact_form">
          <Button
            color={"special"}
            size={"large"}
            icon={<I.UserExpert />}
            primary
            style={{ border: "0px" }}
            fill={"horizontal"}
            hoverIndicator
            label={t.contact_form[l]}
          />
        </Link>
      </Box>
    </>
  ) : (
    <>
      <HeroImage size={size} />
      <Box id={"scrollhere"}>
        <Stack>
          <Box
            background={`url(${load_pic("sink_image", size)})`}
            style={{ border: "0px" }}
            direction="column"
            border={{ color: "brand" }}
            pad="medium"
            align="start"
            className="mask1"
          >
            <Box
              style={{ opacity: "0" }}
              margin={{ left: "5em", right: "5em" }}
            >
              <Heading textAlign="start" level={2}>
                {t.startPageHeading[l]}
              </Heading>
              <Paragraph size={"medium"} textAlign="center">
                {" "}
                {t.startPageParagraph1[l]}
              </Paragraph>
              <Paragraph size={"medium"} textAlign="center">
                {" "}
                {t.startPageParagraph2[l]}
              </Paragraph>
              <Paragraph size={"medium"} textAlign="center">
                {" "}
                {t.startPageParagraph3[l]}
              </Paragraph>
            </Box>
          </Box>
          <Box
            align="start"
            pad={"small"}
            margin={{ left: "5em", right: "5em" }}
          >
            <Heading textAlign="start" level={2} id="mainHeading">
              {t.startPageHeading[l]}
            </Heading>
            <Paragraph size={"medium"} textAlign="center">
              {" "}
              {t.startPageParagraph1[l]}
            </Paragraph>
            <Paragraph size={"medium"} textAlign="center">
              {" "}
              {t.startPageParagraph2[l]}
            </Paragraph>
            <Paragraph size={"medium"} textAlign="center">
              {" "}
              {t.startPageParagraph3[l]}
            </Paragraph>
          </Box>
        </Stack>
        {/* ----------------------------------------------------------- */}
        <Box align="center">
          <Heading textAlign="center" level={1}>
            {t.our_services[l]}
          </Heading>
        </Box>

        <Box align="center">
          <div style={gridStyle}>
            {servicesList.map((s,i) => {
              return (
                <Card
                  key={i}
                  height="small"
                  width="medium"
                  background={`url(${s.image})`}
                  margin={"medium"}
                >
                  <Box align="center" background={"tint-transparent40"}>
                    <Box fill>
                      <Link to={`/services-list/${s.name}`}>
                        <Button
                          fill
                          primary
                          icon={<I.More color="special" />}
                          hoverIndicator
                          label={
                            t.read_more_about[l].charAt(0).toUpperCase() +
                            t.read_more_about[l].slice(1)
                          }
                        />
                      </Link>
                    </Box>

                    <CardHeader pad="medium">
                      <Heading level={3} margin="none" color={"brand"}>
                        {s.name}
                      </Heading>
                    </CardHeader>
                  </Box>
                  <CardBody pad="medium">{}</CardBody>
                  <CardFooter pad={{ horizontal: "small" }} background="brand">
                    <Box fill>
                      <Link to={`/contact_form?spec=${s.name}`}>
                        <Button
                          fill
                          primary
                          icon={<I.Contact color="special" />}
                          hoverIndicator
                          label={t.Contact_Us[l]}
                        />
                      </Link>
                    </Box>
                  </CardFooter>
                </Card>
              );
            })}
          </div>
        </Box>

        {/* ----------------------------------------------------------- */}
        <Box align="center">
          <Heading textAlign="center" level={2}>
            {t.with_us_you_get[l]}
          </Heading>
          <Box pad="medium" direction="row">
            <Box ref={checkboxRef}>
              {" "}
              {check_items.map((item, i) => (
                <CheckBox
                  color="background"
                  checked={true}
                  label={item}
                  pad={"xxsmall"}
                  key={i}
                />
              ))}
            </Box>

            <Image
              fit={"cover"}
              src={guarantee}
              style={{
                backgroundColor: "#transparent100",
                maxHeight: `${checkBoxHeight}px`,
              }}
            />
          </Box>
        </Box>
      </Box>

<Paragraph></Paragraph>


      <Box gridArea="big-text" animation={"fadeIn"} align={"center"}>
        <Link to="/contact_form">
          <Button
            color={"special"}
            size={"large"}
            icon={<I.UserExpert />}
            primary
            style={{ border: "0px" }}
            // fill={"horizontal"}
            hoverIndicator
            label={(t.contact_form as any)[l]}
          />
        </Link>
      </Box>
    </>
  );
};

export default StartPage;
