import {
  Accordion,
  AccordionPanel,
  Box,
  Button,
  Heading,
  Paragraph,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
  Text,
} from "grommet";
import { servicesListType } from "../../helpers/servicesList";
import { optionalTable } from "../../translates/translations";
import * as I from "grommet-icons";
import { useContext, useEffect } from "react";
import { LanguageContext } from "../../App";
import { translations as t } from "../../translates/translations";
import { Link } from "react-router-dom";
import { useDocumentTitle } from "../hooks/useDocumentTitle";
import { constants as c } from "../../constants/constants";
import setMetaTagForCanonicalUrl from "../../helpers/setMetaTagForCanonicalUrl";

interface ServiceViewProps {
  size: string;
  serviceObject: servicesListType | undefined;
}

const ServiceView = ({ size, serviceObject }: ServiceViewProps) => {
  setMetaTagForCanonicalUrl();
  const l = useContext(LanguageContext);
  const gridStyle = {
    display: "grid",
    gap: "0rem",
    gridTemplateColumns: `repeat(2, minmax(200px, 1fr))`,
  };

  useDocumentTitle(serviceObject?.name || "");
  useEffect(() => {
    return () => {
      document.title = `${c.organization_name} ${t.Services[l]}`;
    };
  }, []);

  if (serviceObject === undefined) return null;
  const { name, content } = serviceObject;
  const {
    title,
    paragraph,
    header,
    lists,
    asterix,
    price_title,
    price_list,
    optional_asterix,
    optional_text,
    guarantee,
    optional_table,
  } = content;

  const table = (table: optionalTable) => {
    const { row1, row2 } = table;
    if (row1.length && row2.length && row1.length === row2.length) {
      return (
        <Table>
          <TableHeader>
            <TableRow>
              <TableCell scope="col" border="bottom">
                {row1[0]}
              </TableCell>
              <TableCell scope="col" border="bottom">
                {row2[0]}
              </TableCell>
            </TableRow>
          </TableHeader>

          <TableBody>
            {row1.map((r, i) => {
              if (i !== 0) {
                return (
                  <TableRow key={r}>
                    <TableCell scope="row">
                      <strong>{r}</strong>
                    </TableCell>
                    <TableCell>{row2[i]}</TableCell>
                  </TableRow>
                );
              }
              return null;
            })}
          </TableBody>
        </Table>
      );
    }
  };

  const contact_us_button = () => {
    return (
      <Box gridArea="big-text" animation={"fadeIn"} align="center">
        <Link to={`/contact_form?spec=${serviceObject.name}`}>
          <Button
            size={"large"}
            icon={<I.UserExpert />}
            primary
            style={{ border: "0px" }}
            fill={size === "small" ? "horizontal" : false}
            hoverIndicator
            label={(t.contact_form as any)[l]}
          />
        </Link>
      </Box>
    );
  };

  return (
    <>
      <Box
        style={{ minHeight: "60px" }}
        background={{ color: "background" }}
      ></Box>

      <Box
        animation={"fadeIn"}
        pad={"small"}
        background={{ image: `url(${serviceObject.image})` }}
        style={{ padding: 0 }}
      >
        <Box
          fill
          background={{ color: "tint-transparent40" }}
          margin={"none"}
          align={"center"}
        >
          <Box alignSelf="start" pad={{ top: "1px" }} fill>
            <Link to="/services-list">
              <Button
                fill
                primary
                color={"brand-transparent40"}
                label={t.Go_Back[l]}
                icon={<I.FormPreviousLink />}
              />
            </Link>
          </Box>
          <Heading
            color={"bright-white-always"}
            alignSelf="center"
            textAlign="center"
          >
            {name}
          </Heading>
          <Box pad={{ left: "1em", right: "1em" }}>
            <Heading
              color={"bright-white-always"}
              level={3}
              alignSelf="center"
              textAlign="center"
            >
              {title}
            </Heading>
          </Box>
          <Box pad={{ left: "1em", right: "1em" }}>
            <Paragraph color={"bright-white-always"} textAlign="center">
              {paragraph}
            </Paragraph>
          </Box>
        </Box>
      </Box>

      <Box>
        <Box>
          {header && (
            <Heading level={3} alignSelf="center" textAlign="center">
              {header}
            </Heading>
          )}
          <Box
            pad={{
              left: "1em",
              right: "1em",
              bottom: size !== "small" ? "3em" : "0",
            }}
            align={size !== "small" ? "center" : ""}
          >
            <Accordion width={size !== "large" ? "100%" : "60%"}>
              {lists.map((list) => {
                return name === t.GardenCleaning[l] ? (
                  <Box pad={"medium"} background="brand" key={name}>
                    <ul>
                      {list.items.map((item) => (
                        <li key={item}>
                          <Text key={item} textAlign="center" size={"small"}>
                            {item}
                          </Text>
                        </li>
                      ))}

                      {asterix && (
                        <Text
                          textAlign="start"
                          margin={"small"}
                          color={"special"}
                          size="xsmall"
                        >{`*${asterix}`}</Text>
                      )}
                    </ul>
                  </Box>
                ) : (
                  <AccordionPanel key={list.name} label={list.name}>
                    <Box pad={"medium"} background="brand">
                      <ul>
                        {list.items.map((item) => (
                          <li key={item}>
                            <Text textAlign="center" size={"small"}>
                              {item}
                            </Text>
                          </li>
                        ))}

                        {asterix && (
                          <Text
                            textAlign="start"
                            margin={"small"}
                            color={"special"}
                            size="xsmall"
                          >{`*${asterix}`}</Text>
                        )}
                      </ul>
                    </Box>
                  </AccordionPanel>
                );
              })}
            </Accordion>
          </Box>
        </Box>
        {size === "small" ? (
          <>
            {" "}
            <Box
              height={"xsmall"}
              animation={"fadeIn"}
              // pad={"small"}
              background={{ image: `url(${serviceObject.image})` }}
              style={{ padding: 0, marginTop: "2em" }}
            >
              <Box
                fill
                background={{ color: "tint-transparent40" }}
                margin={"none"}
                align={"center"}
              >
                <Heading
                  textAlign="center"
                  size="small"
                  color={"bright-white-always"}
                >
                  {price_title}
                </Heading>
              </Box>
            </Box>
            {table(optional_table)}
            <Box animation={"slideRight"} direction="column" align="center">
              <ul style={{ listStyle: `none` }}>
                {price_list.map((i, e) => (
                  <li key={i}>
                    <Box direction="row" align="center" pad={"xsmall"}>
                      <I.EmptyCircle
                        color={
                          name === "Flyttstädning" && e === 3
                            ? "red"
                            : "reverse_brand"
                        } //mobile here
                        style={{ maxHeight: "1em" }}
                      />
                      <Text
                        size="small"
                        color={name === "Flyttstädning" && e === 3 ? "red" : ""}
                      >
                        {i}
                      </Text>
                    </Box>
                  </li>
                ))}
              </ul>
            </Box>
            {optional_asterix && (
              <Box pad={{ left: "0.5em", right: "0.5em" }}>
                <Text margin={"xsmall"} color={"special"} size="xsmall">
                  {`*${optional_asterix}`}
                </Text>
              </Box>
            )}
            {optional_table.asterix && (
              <Box pad={{ left: "0.5em", right: "0.5em" }}>
                <Text margin={"xsmall"} size="xsmall">
                  {optional_table.asterix}
                </Text>
              </Box>
            )}
          </>
        ) : (
          <>
            <Box
              pad={{ left: "1em", bottom: "4em", right: "1em" }}
              style={gridStyle}
            >
              <Box
                animation={"fadeIn"}
                // pad={"small"}
                background={{ image: `url(${serviceObject.image})` }}
                style={{ padding: 0 }}
              >
                <Box
                  fill
                  background={{ color: "tint-transparent40" }}
                  margin={"none"}
                  align={"center"}
                >
                  <Heading
                    textAlign="center"
                    size="small"
                    color={"bright-white-always"}
                  >
                    {price_title}
                  </Heading>
                </Box>
              </Box>

              <Box>
                {table(optional_table)}

                <Box animation={"slideRight"} direction="column" align="left">
                  <ul style={{ listStyle: `none` }}>
                    {price_list.map((i, e) => (
                      <li key={i}>
                        <Box direction="row" align="center" pad={"small"}>
                          <I.EmptyCircle
                            color={
                              name === "Flyttstädning" && e === 3
                                ? "red"
                                : "reverse_brand"
                            }
                            style={{ maxHeight: "1em" }}
                          />
                          <Text size="medium" weight={600}>
                            <Text color={"red"}>
                              {name === "Flyttstädning" && e === 3
                                ? "OBS! "
                                : ""}
                            </Text>
                            {i}
                          </Text>
                        </Box>
                      </li>
                    ))}
                  </ul>
                </Box>
                {optional_asterix && (
                  <Box pad={{ left: "0.5em", right: "0.5em" }}>
                    <Text margin={"xsmall"} color={"special"} size="xsmall">
                      {`*${optional_asterix}`}
                    </Text>
                  </Box>
                )}
                {optional_table.asterix && (
                  <Box pad={{ left: "0.5em", right: "0.5em" }}>
                    <Text margin={"xsmall"} size="xsmall">
                      {optional_table.asterix}
                    </Text>
                  </Box>
                )}
              </Box>
            </Box>
          </>
        )}

        {optional_text && (
          <Box
            background={{ color: "brand-transparent20" }}
            pad={{ left: "0.5em", right: "0.5em" }}
            align={"center"}
          >
            <Text
              textAlign="center"
              size="medium"
              color={"special"}
            >{`${optional_text}`}</Text>
          </Box>
        )}
        {contact_us_button()}
        <Box direction="column" margin={"small"} pad={{ top: "1em" }}>
          {guarantee.map((g, i) => {
            return (
              <Text
                textAlign="center"
                key={i}
                weight={i === 0 ? "bold" : "normal"}
              >
                {g}
              </Text>
            );
          })}
          <Link to="/services-list">
            <Button
              primary
              margin={{ top: "1em", bottom: "1em" }}
              fill
              label={t.Go_Back[l]}
              icon={<I.FormPreviousLink />}
            />
          </Link>
        </Box>
      </Box>
    </>
  );
};

export default ServiceView;
