import { Header, Button, Image, Box, Nav } from "grommet";
import * as I from "grommet-icons";
import logo3 from "../img/logo3.png";
import { useContext, useState } from "react";
import Menu from "./Menu";
import useElementIsOnScreen from "./hooks/useElementIsOnScreen";
import { Link } from "react-router-dom";
import { LanguageContext } from "../App";
import { translations as t } from "../translates/translations";
import { scrollUp } from "../helpers/scrollUp";
interface HeaderBarProps {
  size: string;
}

const HeaderBar = (props: HeaderBarProps) => {
  const [menuIsOpen, setMenu] = useState(false);
  const [ref, isVisible] = useElementIsOnScreen();
  const l = useContext(LanguageContext);
  const { size } = props;
  const toggleMenu = () => {
    setMenu(!menuIsOpen);
  };
  const anchor_color = "#5cb85e";

  return (
    <>
      {size !== "small" ? (
        <>
          <div ref={ref} id="headerboi"></div>
          <Nav
            align="left"
            direction="row"
            background={`${isVisible ? "brand" : "brand-transparent92"}`}
            style={{
              position: "absolute",
              top: "0px",
              zIndex: "2",
              width: "100%",
            }}
          >
            <Button
              icon={
                <Image
                  loading="lazy"
                  src={logo3}
                  style={{ maxHeight: "2em" }}
                />
              }
              hoverIndicator
              href={window.location.origin}
            />

            <Box alignSelf="center">
              <Link to="/">
                <Button
                  size={size !== "large" ? "small" : "medium"}
                  style={{ border: "0px", padding: 0 }}
                  label={
                    <Box
                      style={{
                        color: anchor_color,
                        fontWeight: 600,
                      }}
                    >
                      {t.Home[l]}
                    </Box>
                  }
                  onClick={scrollUp}
                  icon={<I.Home size="medium" color={"status-neutral"} />}
                ></Button>
              </Link>
            </Box>

            <Box alignSelf="center">
              <Link to="/services-list">
                <Button
                  size={size !== "large" ? "small" : "medium"}
                  style={{ border: "0px", padding: 0 }}
                  label={
                    <Box
                      style={{
                        color: anchor_color,
                        fontWeight: 600,
                      }}
                    >
                      {t.Services[l]}
                    </Box>
                  }
                  onClick={scrollUp}
                  icon={
                    <I.BusinessService size="medium" color={"status-neutral"} />
                  }
                ></Button>
              </Link>
            </Box>

            <Box alignSelf="center">
              {" "}
              <Link to="/pricelist">
                <Button
                  size={size !== "large" ? "small" : "medium"}
                  style={{ border: "0px", padding: 0 }}
                  label={
                    <Box
                      style={{
                        color: anchor_color,
                        fontWeight: 600,
                      }}
                    >
                      {t.pricelist[l]}
                    </Box>
                  }
                  onClick={scrollUp}
                  icon={<I.Money size="medium" color={"status-neutral"} />}
                ></Button>
              </Link>
            </Box>

            <Box alignSelf="center">
              <Link to="/faq">
                <Button
                  size={size !== "large" ? "small" : "medium"}
                  style={{ border: "0px", padding: 0 }}
                  label={
                    <Box
                      style={{
                        color: anchor_color,
                        fontWeight: 600,
                      }}
                    >
                      {t.faq[l]}
                    </Box>
                  }
                  onClick={scrollUp}
                  icon={
                    <I.CircleQuestion size="medium" color={"status-neutral"} />
                  }
                ></Button>
              </Link>
            </Box>

            {/* <Box alignSelf="center">
              <Link to="/gallery">
              <Button
                
                size={size !== "large" ? "small" : "medium"}
                style={{ border: "0px",padding: 0 }}
                label={<Box  style={{
                      color: anchor_color,
                      fontWeight: 600,
                    }}>{t.pic_gallery[l]}</Box>}
                onClick={scrollUp}
                icon={<I.Image loading="lazy" size="medium" color={"status-neutral"} />}
              ></Button>
            </Link>
            </Box>
             */}

            <Box alignSelf="center">
              {" "}
              <Link to="/policy">
                <Button
                  size={size !== "large" ? "small" : "medium"}
                  style={{
                    border: "0px",
                    padding: 0,
                  }}
                  label={
                    <Box
                      style={{
                        color: anchor_color,
                        fontWeight: 600,
                      }}
                    >
                      {t.Policy[l]}
                    </Box>
                  }
                  onClick={scrollUp}
                  icon={<I.License size="medium" color={"status-neutral"} />}
                ></Button>
              </Link>
            </Box>

            <Box alignSelf="center">
              {" "}
              <Link to="/contacts">
                <Button
                  size={size !== "large" ? "small" : "medium"}
                  style={{
                    border: "0px",
                    padding: 0,
                  }}
                  label={
                    <Box
                      style={{
                        color: anchor_color,
                        fontWeight: 600,
                      }}
                    >
                      {t.Contacts[l]}
                    </Box>
                  }
                  onClick={scrollUp}
                  icon={
                    <I.PhoneVertical size="medium" color={"status-neutral"} />
                  }
                ></Button>
              </Link>
            </Box>
          </Nav>
        </>
      ) : (
        <>
          <div ref={ref} id="headerboi"></div>
          <Header
            background={`${
              isVisible ? "brand-transparent20" : "brand-transparent92"
            }`}
            style={{
              position: "absolute",
              top: "0px",
              zIndex: "2",
              width: "100%",
            }}
          >
            <Button
              icon={
                <Image
                  loading="lazy"
                  src={logo3}
                  style={{ maxHeight: "2em" }}
                />
              }
              hoverIndicator
              href={window.location.origin}
            />
            <Button
              onClick={toggleMenu}
              icon={
                !menuIsOpen ? (
                  <I.Menu color="special" />
                ) : (
                  <I.Close color={"special"} />
                )
              }
            />
          </Header>
          <Menu
            size={props.size}
            toggleMenu={toggleMenu}
            menuIsOpen={menuIsOpen}
          />
        </>
      )}
    </>
  );
};
export default HeaderBar;
