import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import getLanguage from "./helpers/getLanguage";
import Wrapper from "./components/Wrapper";

getLanguage();

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Wrapper />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);


