import { useEffect, useRef, useState, MutableRefObject } from "react";

const useElementIsOnScreen = (
  options = { root: null, rootMargin: "0px", threshold: 1.0 }
): [MutableRefObject<any | null>, boolean] => {
  const containerRef = useRef<any>(null);
  const [isVisible, setIsVisible] = useState(false);

  const callback = (entries: any) => {
    const [entry] = entries;
    setIsVisible(entry.isIntersecting);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(callback, options);

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        observer.unobserve(containerRef.current);
      }
      observer.disconnect();
    };
  }, [containerRef, options]);
  return [containerRef, isVisible];
};

export default useElementIsOnScreen;
