import Card from "../Card";
import { translations as t } from "../../translates/translations";
import { useContext, useEffect } from "react";
import { LanguageContext } from "../../App";
import ServiceView from "./ServiceView";
import { servisesList as sl } from "../../helpers/servicesList";
import { Box, Heading, Paragraph } from "grommet";
import { scrollUp } from "../../helpers/scrollUp";
import { useParams } from "react-router-dom";
import { useDocumentTitle } from "../hooks/useDocumentTitle";
import setMetaTagForCanonicalUrl from "../../helpers/setMetaTagForCanonicalUrl";

interface ServicesPageListProps {
  size: string;
}

const ServicesPageList = ({ size }: ServicesPageListProps) => {
  setMetaTagForCanonicalUrl();
  const l = useContext(LanguageContext);
  const servicesList = sl(l, size);
  const spec = useParams();
  useDocumentTitle(t.Services[l]);
  const getGridColumnNumber = (size: string) => {
    if (size === "medium") {
      return 2;
    }
    return 3;
  };
  const gridStyle = {
    display: "grid",
    gap: "0rem",
    gridTemplateColumns: `repeat(${getGridColumnNumber(
      size
    )}, minmax(200px, 1fr))`,
  };

  useEffect(() => {
    scrollUp();
  }, [spec]);

  return (
    <>
      {servicesList.some((s) => {
        return s.name === Object.values(spec)[0];
      }) ? (
        <ServiceView
          serviceObject={servicesList.find((obj) => {
            return obj.name === Object.values(spec)[0];
          })}
          size={size}
        />
      ) : (
        <>
          <Box
            style={{ minHeight: "60px" }}
            background={{ color: "brand" }}
          ></Box>
          <Box align="center" background={"brand-transparent92"}>
            <Heading textAlign="center">{t.our_services[l]}</Heading>
          </Box>
          <div
            className={size !== "small" ? "grid_card" : "card"}
            style={size !== "small" ? gridStyle : {}}
          >
            {servicesList.map((service, i) => {
              return (
                <Card
                  size={size}
                  key={i}
                  header={service.name}
                  image={service.image && service.image}
                />
              );
            })}
          </div>
        </>
      )}
    </>
  );
};

export default ServicesPageList;
