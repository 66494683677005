export type translation = {
  en: string | servicelists[] | string[] | optionalTable;
  sv: string | servicelists[] | string[] | optionalTable;
};

export type servicelists = {
  name: string;
  items: string[];
};

export type optionalTable = {
  row1: string[];
  row2: string[];
  asterix: string[];
};

export const translations = {
  Hello: { en: "Hello", sv: "Hej" },
  Home: { en: "Home", sv: "Hem" },
  Services: { en: "Services", sv: "Tjänster" },
  About_Us: { en: "About Us", sv: "Om Oss" },
  Policy: { en: "Policy", sv: "Policy" },
  Contacts: { en: "Contact Us", sv: "Kontakta Oss" },
  Hero_Button1_Text: { en: "Home Cleaning", sv: "FÖR DIN" },
  Hero_Button2_Text: { en: "empty", sv: "ENKLARE VARDAG " },
  Hero_Image_Text: {
    en: "empty",
    sv: "Välkommen till Städkamrater i Karlstad med omnejd",
  },
  pricelist: { en: "Price List", sv: "Priser" },
  faq: { en: "F.A.Q", sv: "Vanliga frågor" },
  contact_form: { en: "Contact/Book Us Now", sv: "Kontakta/Boka Oss Nu" },
  pic_gallery: { en: "Picture Gallery", sv: "Bildgalleri" },
  startPageHeading: {
    en: "not translated",
    sv: "Prioritera det du tycker är viktigast i livet så hjälper vi till med städningen!",
  },
  startPageParagraph1: {
    en: "empty",
    sv: `Vi är en pålitlig och lyhörd städfirma med mångårig erfarenhet inom städ som vill underlätta i din vardag eller företagande. Vi finns för att kunna erbjuda dig precis den städhjälp du behöver. Med oss kan du känna dig trygg genom hela städprocessen då vi ser till att din städning blir både smidig, kostnadseffektiv och utfört med kvalitet. `,
  },
  startPageParagraph2: {
    en: "empty",
    sv: `Vår målsättning är ett företag med nöjda kunder och medarbetare. Vi tror på att nöjda medarbetare gör kunder nöjda därför vi jobbar hårt för att vara en bra arbetsplats för våra medarbetare. `,
  },
  startPageParagraph3: {
    en: "empty",
    sv: `Unna dig en städkamrat genom att kontakta oss och boka ett första gratis kundbesök. Under kundbesöket går vi tillsammans igenom dina önskemål och skräddarsyr en städningsplan utifrån dina önskemål och behov.  `,
  },
  HomeCleaning: { en: "empty", sv: "Hemstädning" },
  BigCleaning: { en: "empty", sv: "Storstädning" },
  MoveCleaning: { en: "empty", sv: "Flyttstädning" },
  WindowCleaning: { en: "empty", sv: "Fönsterputsning" },
  WorkplaceCleaning: { en: "empty", sv: "Kontorsstädning" },
  GardenCleaning: { en: "empty", sv: "Trädgårdsarbete" },
  our_services: { en: "Our services:", sv: "Våra städ- och trädgårdstjänster" },
  read_more_about: { en: "read more about", sv: "läs mer om" },
  with_us_you_get: { en: "empty", sv: "Hos oss får du" },
  start_checklist_item_1: {
    en: "empty",
    sv: "lyhörd kontaktperson som du alltid kan ringa",
  },
  start_checklist_item_2: {
    en: "empty",
    sv: "erfaren och professionell personal",
  },
  start_checklist_item_3: { en: "empty", sv: "heltäckande ansvarsförsäkring" },
  start_checklist_item_4: {
    en: "empty",
    sv: "automatisk hantering av RUT-avdraget",
  },
  start_checklist_item_5: {
    en: "empty",
    sv: "och nöjdkundgaranti ingår alltid!",
  },
  home_cleaning_service_title: {
    en: "empty",
    sv: "Välj oss för städning hemma hos dig i Karlstad med omnejd!",
  },
  home_cleaning_service_paragraph: {
    en: "empty",
    sv: "Ett rent hem är viktigt för oss alla, men det kan ibland vara svårt att få tiden och energi att räcka till. Fler och fler inser fördelarna med att komma hem till en ren bostad och att slippa lägga energi och tid på städning. Så släpp kraven och låt oss ta hand om din hemstädning. Att komma hem ska vara en ren njutning och vi ser till att underlätta din vardag genom att du kan släppa tankarna på städning och fokusera på de andra viktiga punkterna i livet.",
  },
  home_cleaning_service_header: {
    en: "empty",
    sv: "Följande ingår i hemstädning:",
  },
  home_cleaning_service_lists: {
    en: [
      {
        name: "empty:",
        items: ["empty"],
      },
      {
        name: "empty:",
        items: ["Empty"],
      },
    ],
    sv: [
      {
        name: "Generellt för alla rum:",
        items: [
          "Dammsugning och avtorkning av golv och golvlister.",
          "Putsning av speglar, avtorkning av synlig smuts på kontakter, dörrar och karmar.",
          "Vid behov dammtorkning av tavlor.",
          "Dammtorkning av lampor och lampfötter.",
          "Dammtorkning av fönsterbrädor.",
          "Avtorkning av synliga fläckar på dörrar.",
          "Avtorkning av möbler med fria ytor (maxhöjd 1,8 m).",
          "Dammsugning av klädda/stoppade möbler.",
          "Dammsugning av golv och mattor.",
          "Tömning av papperskorgar.",
          "Avfläckning av speglar.",
        ],
      },
      {
        name: "Kök:",
        items: [
          "Rengöring av diskbänk, arbetsbänk och spis samt vid behov ovanför dessa.",
          "Avtorkning av kran och kakel ovanför diskbänk.",
          "Avtorkning av synlig smuts på köksluckor och runt handtag på köksskåp.",
          "Avtorkning av eventuella hyllor.",
          "Tömning av sopbehållare.",
          "Rengöring av ut- och insidan i skåp där sopbehållare finns.",
          "Avtorkning av utsidan på vitvaror och fläkt.",
          "Rengöring av mikrovågsugn (ut- och invändigt).",
          "Dammsugning av eventuella mattor.",
        ],
      },
      {
        name: "Badrum / dusch / tvättstuga:",
        items: [
          "Rengöring av badkar, handfat och toalett.",
          "Avtorkning på utsidan av vitvaror.",
          "Avtorkning av eventuella hyllor.",
          "Rengöring av duschkabinväggar / duschväggar.",
          "Avtorkning av fläckar och runt handtag på badrumsskåp.",
          "Tömning av papperskorgar.",
          "Rengöring av kranar och rör som man kommer åt.",
        ],
      },
      {
        name: "Att tänka på inför hemstädning:",
        items: [
          "Plocka undan leksaker, kläder m.m för att vi ska komma åt ordentligt.",
          "Se till att diskbänk och arbetsbänkar är fria från saker.",
        ],
      },
    ],
  },
  home_cleaning_service_asterix: {
    en: "",
    sv: "Om du behöver hjälp med att plocka undan går det givetvis bra att lägga till det för hemstädning. Så löser vi även förberedelserna.",
  },
  home_cleaning_service_price_title: {
    en: "",
    sv: "Pris för hemstädning (efter RUT-avdrag):",
  },
  home_cleaning_service_price_list: {
    en: ["empty"],
    sv: [
      "225 kr per timme efter RUT-avdrag.",
      "Inställelseavgift* 120 kr per städtillfälle.",
      "Allt städmaterial och städutrustning ingår.",
    ],
  },
  home_cleaning_service_optional_table: {
    en: {
      row1: [],
      row2: [],
      asterix: [],
    },
    sv: {
      row1: [],
      row2: [],
      asterix: [],
    },
  },
  home_cleaning_service_optional_asterix: {
    en: "empty",
    sv: "Inställelseavgift inkluderar resa, städmaterial, fönsterputsmaterial, medarbetarens förberedelser och administration. Vi tar ut en inställelseavgift eftersom Skatteverket inte kan bedöma om kostnaden för material och resa är inbakad i priset för arbetskostnader och kan därmed inte godkänna utbetalning av rutavdrag om man inte kan specificera på fakturan att man tar betalt för resa och material.",
  },
  home_cleaning_service_optional_text: {
    en: "empty",
    sv: "Kontakta oss för ett gratis hembesök eller mer information! Vi svarar gärna på dina frågor. Under kundbesöket går vi tillsammans igenom dina önskemål och skräddarsyr en städningsplan utifrån dina behov.",
  },
  home_cleaning_service_guarantee: {
    en: ["empty"],
    sv: [
      "Nöjdkundgaranti ingår alltid.",

      "Vi vill bara ha nöjda kunder! Det kan hända att vi gör fel eller missar något, men då vill vi veta det och få rätta till det så att du blir nöjd.",

      "Vi vill att du kontaktar oss om du inte är nöjd av någon anledning. Kvalitetsgaranti gäller i 24 timmar efter utförd städning.",
    ],
  },

  big_cleaning_service_title: {
    en: "empty",
    sv: "Hör av dig så skräddarsyr vi en storstädningsplan just utifrån dina önskemål och behov.",
  },
  big_cleaning_service_paragraph: {
    en: "empty",
    sv: "Varje hushåll mår bra av en storstädning några gånger om året. Unna dig en grundlig rengöring, med bland annat städning i kökets skåp och lådor, och alla andra ytor som inte ingår i en vanlig hemstädning. Låt oss göra jobbet och upplev doften och känslan av ett totalt rengjort hem. Du väljer själv vad ska ingå i storstädning hemma hos dig. Du kan till exempel välja storstädning i enbart kök eller badrum eller både och. där följande städmoment kan ingå:",
  },
  big_cleaning_service_header: {
    en: "",
    sv: "",
  },
  big_cleaning_service_lists: {
    en: [
      {
        name: "empty:",
        items: ["empty"],
      },
      {
        name: "empty:",
        items: ["item", "item2"],
      },
    ],
    sv: [
      {
        name: "Köket:",
        items: [
          "Avtorkning av diskbänk, arbetsbänk, kranar, undersida diskbänk samt avloppsrör i kök. Putsar diskho, blandare, propp och sil. ",
          "Avtorkning av kakel ovanför diskbänk/arbetsbänk/spis.",
          "Avtorkning av kyl och frys utvändigt och invändigt, även under om möjligt. De måste vara tömda och avfrostade innan städning påbörjas. Även bakom om kund har dragit fram kyl/frys. ",
          "Torkar köksluckor ut- och invändigt., lådor och handtag.",
          "Rengör ovanpå skåp och hyllor. ",
          "Torkar diskmaskin ut- och invändigt.",
          "Rengör fläkt och fläktfilter (ej kolfilter). ",
          "Avtorkning av socklar vid golvet på underskåp och övriga snickerier",
          "Tömning och avtorkning i och runt sopbehållare.",
          " Ugnsrengöring samt avtorkning av ugnsplåtar och galler. Spisen dras fram om möjligt och dammsugning och våttorkning görs under och bakom samt avtorkning mellan glas (demontering av kund). Typ av spishäll specificeras vid bokning. ",
          "Rengör mikrovågsugn ut- och invändigt.",
        ],
      },
      {
        name: "Badrum:",
        items: [
          "Avkalkning/rengöring av badkar (front demonteras och monteras av kund).",
          " Avtorkning i badrumsskåp om de är tömda. ",
          " Avtorkning av skåpsluckor in/utvändigt samt front till lådor och handtag. ",
          " Avkalkning av duschkabin och i duschutrymme. Avfettar och avkalkar väggar och fogar i duschutrymme om det är möjligt. ",
          " Rengör utsidan av vitvaror samt i tvättmedelsbehållaren på tvättmaskinen. ",
          "Avtorkning i golvbrunn. ",
          "Rengör filtret i torktumlaren. ",
          "Rengör utsidan av vitvaror samt i tvättmedelsbehållaren på tvättmaskinen.",
          "Avkalkning och avtorkning av kakel/klinkers (ej mögel). ",
          "Avtorkning av kranar/blandare, duschslang/duschmunstycke (ej vattenlås). ",
          "Avtorkning av vågräta VVS-rör (högst 2,5 m). ",
          "Rengöring av toalettstol i och utanpå. ",
          "Rengöring av handfat inklusive avtorkning av undersida. ",
        ],
      },
      {
        name: "Rum:",
        items: [
          "Avtorkning med torr trasa fast belysning.",
          "Avtorkning med torr trasa av eluttag, strömbrytare och elskåp. Avtorkning av dörr och dörrkarmar, avtorkning dörrhandtag. ",
          "Avtorkning insida av entrédörren. ",
          "Avfläckning av inglasade dörrar, inklusive putsning av fönsterdel upp till 1,8 m. ",
          "Avtorkning av element och horisontella elementrör, även borstning bakom.",
          "Avtorkning och dammsugning av garderober in och utvändigt om de är tömda. ",
          "Dammsugning av golv, trösklar samt mattor. Våttorkning av golv. Notera att vi inte flyttar tyngre möbler. ",
          "Dammsugning av golvlister inkl fläckborttagning. ",
          "Dammsugning av klädda/stoppade möbler. Avtorkning med fuktig trasa av fria horisontella ytor på bord, stolar, skåp och hyllor (högst 2,5 m). ",
          "Tömning av papperskorgar i alla rum. ",
          "Putsning av speglar. ",
          "Borttagning av spindelväv i tak. ",
          "Damning av tavlor. ",
          "Avtorkning av ledstänger och trappräcken. ",
          "Sopning/dammsugning samt våttorkning av trappsteg. ",
          "Avtorkning in- och utvändigt av torkskåp/tumlare och tvättmaskin. ",
          "Rengöring av tvättmedelsfack. ",
          "Avtorkning utsida ventil (högst 2,5 m). ",
          "Väggar dammas vid behov och spindelväv tas bort. ",
          "Avtorkning av väggpanel.",
        ],
      },
      {
        name: "Vi kan också hjälpa dig med: ",
        items: [
          "Putsning av inglasad balkong, halva eller hela partier. ",
          "Balkongstädning. ",
          "Sopning av balkong.",
          "Rengöring av fönsterbleck. ",
          "Avtorkning fönsterkarmar. ",
          "Avtorkning fönsternisch. ",
          "Rengöring hushållsmaskiner. ",
          "Bädda rent säng. ",
          "Rengöring av väggar.",
        ],
      },
      {
        name: "Att tänka på inför storstädning:",
        items: [
          "Plocka undan leksaker, kläder med mera för att vi ska komma åt ordentligt.",
          "Se till att diskbänk och arbetsbänkar är fria från saker.",
          "Plocka även bort saker som blomkrukor, vaser etc. från fönsterbrädorna som kan vara i vägen för att öppna fönstren om ni valt tillvalet fönsterputsning.",
          "Vill kunden få det städat bakom möbler som soffor etc. måste kunden själv flytta på möblerna. Vår personal flyttar inte på större möbler pga. skaderisk.",
        ],
      },
    ],
  },
  big_cleaning_service_asterix: {
    en: "",
    sv: "Om du behöver hjälp med att plocka undan går det givetvis bra att lägga till det för storstädning. Så löser vi även förberedelserna.",
  },
  big_cleaning_service_price_title: {
    en: "",
    sv: "Pris för storstädning (efter RUT-avdrag):",
  },
  big_cleaning_service_price_list: {
    en: ["empty"],
    sv: [
      "225 kr per timme efter RUT-avdrag.",
      "Inställelseavgift* 120 kr per städtillfälle.",
      "Allt städmaterial och städutrustning ingår.",
    ],
  },
  big_cleaning_service_optional_table: {
    en: {
      row1: [],
      row2: [],
      asterix: [],
    },
    sv: {
      row1: [],
      row2: [],
      asterix: [],
    },
  },
  big_cleaning_service_optional_asterix: {
    en: "empty",
    sv: "Inställelseavgift inkluderar resa, städmaterial, fönsterputsmaterial, medarbetarens förberedelser och administration. Vi tar ut en inställelseavgift eftersom Skatteverket inte kan bedöma om kostnaden för material och resa är inbakad i priset för arbetskostnader och kan därmed inte godkänna utbetalning av rutavdrag om man inte kan specificera på fakturan att man tar betalt för resa och material. ",
  },
  big_cleaning_service_optional_text: {
    en: "empty",
    sv: "Kontakta oss om du vill boka oss eller om du har frågor eller funderingar! Vi svarar gärna på dina frågor. ",
  },
  big_cleaning_service_guarantee: {
    en: ["empty"],
    sv: [
      "Nöjdkundgaranti ingår alltid.",

      "Vi vill bara ha nöjda kunder! Det kan hända att vi gör fel eller missar något, men då vill vi veta det och få rätta till det så att du blir nöjd.",

      "Vi vill att du kontaktar oss om du inte är nöjd av någon anledning. Kvalitetsgaranti gäller i 24 timmar efter utförd städning.",
    ],
  },

  workplace_cleaning_service_title: {
    en: "empty",
    sv: "Hör av dig så skräddarsyr vi en kontorstädningsplan just utifrån dina önskemål och behov.",
  },
  workplace_cleaning_service_paragraph: {
    en: "empty",
    sv: "Med Städkamrater får ditt företag prisvärd, kvalitetssäkrad kontorsstädning med personlig service. Oavsett hur stort det är. Kontakta oss och skaffa en bekymmersfri kontorsstädning!",
  },
  workplace_cleaning_service_header: {
    en: "",
    sv: "Detta ingår i kontorsstädning:",
  },
  workplace_cleaning_service_lists: {
    en: [
      {
        name: "empty:",
        items: ["empty"],
      },
      {
        name: "empty:",
        items: ["item", "item2"],
      },
    ],
    sv: [
      {
        name: "Städning av samtliga kontor och rum:",
        items: [
          "Dammsugning av golv och mattor.",
          "Samtliga golv fuktmoppas.",
          "Tömning av papperskorgar.",
          "Damning av fria ytor.",
          "Fläckborttagning på lister, speglar och dörrar.",
        ],
      },
      {
        name: "Städning av toaletter:",
        items: ["In- och utvändig rengöring av samtliga toaletter."],
      },
      {
        name: "Städning av kök eller pentry:",
        items: [
          "Rengöring av kök och pentry.",
          "Rengöring av arbetsbänk och diskbänk.",
          "Rengöring av mikrovågsugn.",
          "Tömning av papperskorg.",
        ],
      },
      {
        name: "Förbrukningsmaterial:",
        items: [
          "Vill ni slippa hanteringen av förbrukningsmaterial? Låt oss ta hand om beställning och påfyllning av tvål, toalettpapper och annat förbrukningsmaterial.",
        ],
      },
    ],
  },
  workplace_cleaning_service_asterix: {
    en: "",
    sv: "",
  },
  workplace_cleaning_service_price_title: {
    en: "",
    sv: "Pris för kontors/företagsstädning",
  },
  workplace_cleaning_service_price_list: {
    en: ["empty"],
    sv: [
      "360 kr per timme.",
      "Inställelseavgift* 96 kr per städtillfälle. Priser för företag är ex moms och RUT-avdrag gäller inte för företag.",
    ],
  },
  workplace_cleaning_service_optional_table: {
    en: {
      row1: [],
      row2: [],
      asterix: [],
    },
    sv: {
      row1: [],
      row2: [],
      asterix: [],
    },
  },
  workplace_cleaning_service_optional_asterix: {
    en: "empty",
    sv: "Inställelseavgift inkluderar resa, städmaterial, medarbetarens förberedelser och administration. ",
  },
  workplace_cleaning_service_optional_text: {
    en: "empty",
    sv: "Kontakta oss om du vill boka oss eller om du har frågor eller funderingar! Vi svarar gärna på dina frågor.",
  },
  workplace_cleaning_service_guarantee: {
    en: ["empty"],
    sv: [
      "Nöjdkundgaranti ingår alltid.",

      "Vi vill bara ha nöjda kunder! Det kan hända att vi gör fel eller missar något, men då vill vi veta det och få rätta till det så att du blir nöjd.",

      "Vi vill att du kontaktar oss om du inte är nöjd av någon anledning. Kvalitetsgaranti gäller i 24 timmar efter utförd städning.",
    ],
  },

  window_cleaning_service_title: {
    en: "empty",
    sv: "Låt solen stråla in genom dina rena och glänsande fönster! Vi tar gärna hand om dina två-, fyr -eller sexsidiga fönster.",
  },
  window_cleaning_service_paragraph: {
    en: "empty",
    sv: "Minst två gånger per år bör fönsterna putsas för att du ska få uppleva lyftet av sol som lyser in genom fönsterna och ger ett lyft i hemmet. Städkamrater putsar fönsterna med egen professionell utrustning och du behöver därför inte oroa dig om att tillhandahålla någon utrustning.",
  },
  window_cleaning_service_header: {
    en: "",
    sv: "Att tänka på inför:",
  },
  window_cleaning_service_lists: {
    en: [
      {
        name: "",
        items: [""],
      },
    ],
    sv: [
      {
        name: "Fönsterputsning",
        items: [
          "Om ni förbereder för fönsterputsaren genom att ta ner gardiner, plocka undan i fönsterbänk och framför fönster så gör Ni det enklare för oss att göra en snygg fönsterputsning.",
        ],
      },
    ],
  },
  window_cleaning_service_asterix: {
    en: "",
    sv: "Om du behöver hjälp med att plocka undan går det givetvis bra att lägga till det för fönsterputsning. Så löser vi även förberedelserna.",
  },
  window_cleaning_service_price_title: {
    en: "",
    sv: "Pris för fönsterputsning (efter RUT-avdrag):",
  },
  window_cleaning_service_price_list: {
    en: ["empty"],
    sv: [
      "225 kr per timme efter RUT-avdrag.",
      "Inställelseavgift* 120 kr per städtillfälle.",
      "Allt städmaterial och städutrustning ingår."
    ],
  },
  window_cleaning_service_optional_table: {
    en: {
      row1: [],
      row2: [],
      asterix: [],
    },
    sv: {
      row1: [],
      row2: [],
      asterix: [],
    },
  },
  window_cleaning_service_optional_asterix: {
    en: "empty",
    sv: "Inställelseavgift inkluderar resa, städmaterial, fönsterputsmaterial, medarbetarens förberedelser och administration. Vi tar ut en inställelseavgift eftersom Skatteverket inte kan bedöma om kostnaden för material och resa är inbakad i priset för arbetskostnader och kan därmed inte godkänna utbetalning av rutavdrag om man inte kan specificera på fakturan att man tar betalt för resa och material.",
  },
  window_cleaning_service_optional_text: {
    en: "empty",
    sv: "",
  },
  window_cleaning_service_guarantee: {
    en: ["empty"],
    sv: [
      "Nöjdkundgaranti ingår alltid.",

      "Vi vill bara ha nöjda kunder! Det kan hända att vi gör fel eller missar något, men då vill vi veta det och få rätta till det så att du blir nöjd.",

      "Vi vill att du kontaktar oss om du inte är nöjd av någon anledning. Kvalitetsgaranti gäller i 24 timmar efter utförd städning.",
    ],
  },

  move_cleaning_service_title: {
    en: "empty",
    sv: "Ska du flytta eller sälja din bostad? ",
  },
  move_cleaning_service_paragraph: {
    en: "empty",
    sv: "Det vet du säkert hur viktigt det är att lämna det gamla hemmet i perfekt skick. Det vet vi med. Låt oss därför sköta din flyttstädning så slipper du all stress i flyttstädningen. Våra professionella städare gör ordentligt rent in i minsta detalj så att den som kommer flytta inkommer mötas av ett skinande hem. Vi lämnar alltid garanti på flyttstädningen vilket gör att eventuella invändningar som kan dyka upp vid besiktningstillfället åtgärdas snarast av oss.",
  },
  move_cleaning_service_header: {
    en: "",
    sv: "Detta ingår i flyttstädning:",
  },
  move_cleaning_service_lists: {
    en: [
      {
        name: "",
        items: [""],
      },
    ],
    sv: [
      {
        name: "Flyttstädning av kök",
        items: [
          "Avtorkning av belysning.",
          "Rengöring av kyl, sval och frys in- och utvändigt samt under.",
          "Rengöring av spis in- och utvändigt, inklusive ugnsplåtar.",
          "Köksfläkt rengöres, inklusive dess ventiler.",
          "Skåp, lådor och bänkar rengörs både ut- och invändigt.",
          "Diskmaskin rengöres in- och utvändigt.",
        ],
      },
      {
        name: "Flyttstädning av bostadsrum",
        items: [
          "Rengöring av samtliga golv.",
          "In- och utvändig rengöring av garderober.",
          "Rengöring av kontakter, dörrar, karmar, element och fönsterbrädor.",
        ],
      },
      {
        name: "Rengöring av badrum och toalett",
        items: [
          "Rengöring av kakelväggar.",
          "Rengöring av golvbrunnar och ventiler.",
          "Rengöring av samtliga förvaringsutrymmen.",
          "Avtorkning av belysning.",
          "In- och utvändig rengöring av toalettstol, badkar och jacuzzibadkar.",
          "Torktumlare, tvättmaskin samt torkskåp rengöres in- och utvändigt.",
        ],
      },
      {
        name: "Fönsterputs",
        items: ["Fönsterputs ingår alltid vid bokad flyttstädning",
          "OBS! Inglasad balkong kostar 550 kr extra efter RUT-avdrag.",
          "OBS! Inglasad altan kostar 750 kr extra efter RUT-avdrag.",
        ],
      },
      {
        name: "Att tänka på inför flyttstädningen",
        items: [
          "Tänk på att hemmet bör vara helt tomt när vi kommer för att vi skall kunna utföra städningen på ett bra sätt. Kyl och frys bör också vara urplockade avstängda och utdragna så att vi kommer åt att städa. Om det finns befintliga skador, som exempelvis fönster som inte går att öppna eller trasiga vitvaror, är det viktigt att du meddelar oss om dessa innan vi påbörjar städningen. Tänk också på att meddela oss om du vill att vi städar biytor såsom balkong, förråd eller garage eftersom detta bör beställas innan vi påbörjar städningen. ",
        ],
      },
    ],
  },
  move_cleaning_service_asterix: {
    en: "",
    sv: "",
  },
  move_cleaning_service_price_title: {
    en: "",
    sv: "Priser för flyttstädning (efter RUT-avdrag)",
  },
  move_cleaning_service_price_list: {
    en: ["empty"],
    sv: [
      "Minsta debitering 2100kr efter RUT-avdrag. ",
      "Inställelseavgift* 120 kr per städtillfälle.",
      "Allt städmaterial och städutrustning ingår.",
      "Gammal/hård att ta bort smuts ** från 20 % extra",
    ],
  },
  move_cleaning_service_optional_table: {
    en: {
      row1: ["row1", "row2"],
      row2: ["cell1", "cell2"],
      asterix: [],
    },
    sv: {
      row1: ["Storlek (kvm)", "50 - 99 kvm ", "100 kvm och uppåt"],
      row2: ["Pris efter RUT-avdrag", "43 kr/kvm", "39 kr/kvm"],
      asterix: [
        "** Bostäder som var ostädade för flera år. Fett, smuts och mögel som samlade i olika ytor behöver behandling med starka rengöringsmedel.",
      ],
    },
  },
  move_cleaning_service_optional_asterix: {
    en: "empty",
    sv: "Inställelseavgift inkluderar resa, städ-fönsterputsmaterial, medarbetarens förberedelser och administration. Vi tar ut en inställelseavgift eftersom Skatteverket inte kan bedöma om kostnaden för material och resa är inbakad i priset för arbetskostnader och kan därmed inte godkänna utbetalning av rutavdrag om man inte kan specificera på fakturan att man tar betalt för resa och material. ",
  },
  move_cleaning_service_optional_text: {
    en: "empty",
    sv: "Kontakta oss om du vill boka oss eller om du har frågor eller funderingar! Vi svarar gärna på dina frågor.",
  },
  move_cleaning_service_guarantee: {
    en: ["empty"],
    sv: [
      "Nöjdkundgaranti ingår alltid.",

      "Vi vill bara ha nöjda kunder! Det kan hända att vi gör fel eller missar något, men då vill vi veta det och få rätta till det så att du blir nöjd.",

      "Vi vill att du kontaktar oss om du inte är nöjd av någon anledning. Kvalitetsgaranti gäller i 24 timmar efter utförd städning.",
    ],
  },

  garden_cleaning_service_title: {
    en: "empty",
    sv: "",
  },
  garden_cleaning_service_paragraph: {
    en: "empty",
    sv: "När tiden eller energi inte räcker till för att ta hand om sin trädgård kan vi på Städkamrater hjälpa till. Våra skickliga medarbetare kan hjälpa dig med att:",
  },
  garden_cleaning_service_header: {
    en: "",
    sv: "",
  },
  garden_cleaning_service_lists: {
    en: [
      {
        name: "",
        items: [""],
      },
    ],
    sv: [
      {
        name: "",
        items: [
          "klippa gräs och trimma kanterna",
          "klippa buskar och häckar",
          "kratta bort höstlöven",
          "beskära träd",
          "eller berätta vad behöver du hjälp med i din trädgård så står vi mer än gärna till tjänst.",
        ],
      },
    ],
  },
  garden_cleaning_service_asterix: {
    en: "",
    sv: "",
  },
  garden_cleaning_service_price_title: {
    en: "",
    sv: "Priser för trädgårdsarbete (efter RUT-avdrag)",
  },
  garden_cleaning_service_price_list: {
    en: ["empty"],
    sv: [
      "225 kr per timme efter RUT-avdrag.",
      "Inställelseavgift* 120 kr per städtillfälle.",
      "Trädgårdsredskap tar vi med oss.",
    ],
  },
  garden_cleaning_service_optional_table: {
    en: {
      row1: ["row1", "row2"],
      row2: ["cell1", "cell2"],
      asterix: [],
    },
    sv: {
      row1: [],
      row2: [],
      asterix: [],
    },
  },
  garden_cleaning_service_optional_asterix: {
    en: "empty",
    sv: "Inställelseavgift inkluderar resa, medarbetarens förberedelser och administration.",
  },
  garden_cleaning_service_optional_text: {
    en: "empty",
    sv: "Kontakta oss om du vill boka oss eller om du har frågor eller funderingar! Vi svarar gärna på dina frågor.",
  },
  garden_cleaning_service_guarantee: {
    en: ["empty"],
    sv: [
      "Nöjdkundgaranti ingår alltid.",

      "Vi vill bara ha nöjda kunder! Det kan hända att vi gör fel eller missar något, men då vill vi veta det och få rätta till det så att du blir nöjd.",

      "Vi vill att du kontaktar oss om du inte är nöjd av någon anledning. Kvalitetsgaranti gäller i 24 timmar efter utförd städning.",
    ],
  },
  Name: { en: "Name", sv: "Namn" },
  Surname: { en: "Surname", sv: "Efternamn" },
  Email: { en: "E-Mail", sv: "Email" },
  Number: { en: "Number", sv: "Nummer" },
  Adress: { en: "Adress", sv: "Adress" },
  What_Subject_Your_Question_Regards_To: {
    en: "What subject your question regards to",
    sv: "Vad gäller din fråga",
  },
  Please_Specify: { en: "Please specify", sv: "Vänligen specificera" },
  Please_Enter_Your_Inquiry: {
    en: "Please enter your inquiry",
    sv: "Ange din fråga",
  },
  I_Prefer_To_Be_Contacted_By: {
    en: "I prefer to be contacted by",
    sv: "Jag föredrar att bli kontaktad via",
  },
  Mobile_Landline: { en: "Mobile / Landline", sv: "Mobil / Telefon" },
  SMS: { en: "SMS", sv: "SMS" },
  Submit: { en: "Submit", sv: "Skicka in" },
  Reset: { en: "Reset", sv: "Återställa" },
  Sending: { en: "Sending", sv: "Skickar" },
  Fake_Adress_Placeholder: {
    en: "Horsta 6, 571 32 NÄSSJÖ ",
    sv: "Högheden sjöbovik 2, 393 63 KALMAR",
  },
  Payments: { en: "Payments", sv: "Betalningar" },
  Bookings: { en: "Bookings", sv: "Bokningar" },
  Cleaning: { en: "Cleaning", sv: "Städning" },
  Something_Else: { en: "Something Else", sv: "Något annat" },
  Sent: { en: "Sent", sv: "Skickad" },
  Go_Back: { en: "Go Back", sv: "Tillbaka" },
  Workdays: { en: "Monday - Friday", sv: "Måndag - Fredag" },
  Weekends: { en: "Saturday - Sunday", sv: "Lördag - Söndag" },
  CLOSED: { en: "CLOSED", sv: "STÄNGT" },
  we_received_your_message: {
    en: "We received your message !",
    sv: "Vi har tagit emot ditt meddelande !",
  },
  scroll_down: { en: "Scroll Down", sv: "Scrolla ned" },
  FROM: { en: "from", sv: "från" },
  PRICE: { en: "Price", sv: "Pris" },
  SERVICE_NAME: { en: "Service Name", sv: "Tjänst" },
  REQUIRED: { en: "Required", sv: "Obligatorisk" },
  INVALID: { en: "Invalid", sv: "Ogiltig" },
  CANT_CONTAIN_NUMBERS: {
    en: "CAN'T CONTAIN NUMBERS",
    sv: "KAN INTE INNEHÅLLA NUMMER",
  },
  TOO_LONG: {
    en: "TOO LONG, MAX 100 CHARACTERS",
    sv: "FÖR LÅNG, MAX 100 TECKEN",
  },
  EMAIL_IS_NOT_VALID: {
    en: "EMAIL IS NOT VALID OR NOT RFC 2822 COMPLIANT",
    sv: "E-POST ÄR INTE GILTIGT",
  },
  NUMBERS_MISSING: {
    en: "SOME NUMBERS ARE MISSING",
    sv: "NÅGRA NUMMER SAKNAS",
  },
  WRONG_COUNTRY_CODE: { en: "WRONG COUNTRY CODE", sv: "FEL LANDSKOD" },
  INVALID_NUMBER: { en: "INVALID NUMBER", sv: "OGILTIGT NUMMER" },
  information_about_GDPR_header: {
    en: "empty",
    sv: "Information till våra kunder i samband med den nya GDPR-lagstiftningen",
  },
  information_about_GDPR_paragraph_p1: {
    en: "empty",
    sv: `Mot bakgrund av den nya EU: s allmänna databeskrivningsförordning
    2016/679 (GDPR), så informerar `,
  },
  information_about_GDPR_paragraph_p2: {
    en: "empty",
    sv: ` dig om följande: `,
  },
  information_about_GDPR_paragraph2_p1: {
    en: "empty",
    sv: `Vi registrerar dig som kund i bokföringen för att uppfylla de krav som
    ställs i Bokföringslagen (Bfl), för att kunna hantera
    RUT-avdrag gentemot skatteverket samt för att kunna kommunicera med dig
    som kund. Företaget sparar Namn, fakturaadress, personnummer,
    telefonnummer och e-postadress. Företaget delar med sig av information
    till Skatteverket samt till Inkasso i det fall som det är aktuellt. Du
    har enligt GDPR rätt att ta del av den information som vi har sparat
    om dig, samt rätta felaktiga uppgifter. Dock så kan vi ej ta bort
    uppgifter som krävs av oss för att kunna uppfylla kraven i
    bokföringslagen (Bfl). Informationen i bokföringen sparas i minst 7 år
    från den senaste affärshändelsen, detta för att uppfylla kraven i
    Bokföringslagen. Vid frågor vi kan nås på e-postadressen  `,
  },
  or: {
    en: "or",
    sv: ` eller `,
  },
  how_do_we_store_data: {
    en: "How do we store data",
    sv: " Hur sparas informationen",
  },
  vi_have_bokforingen_at: { en: "", sv: "Vi har bokföringen hos" },
  vi_have_bokforingen_at_p2: {
    en: "",
    sv: "De tar ansvar för att lagringen är säker och följer det regelverk som lagen beskriver. Mer om dem och GDPR via denna länk.",
  },
  Contact_Us: { en: "Contact Us", sv: "Kontakta Oss !" },
  Our_Working_Hours: { en: "Our work hours", sv: "Våra öppettider" },
  you_may_call_or_sms_us: { en: "", sv: "Du kan ringa eller smsa oss på" },
  you_may_even_reach_us_by_email: {
    en: "",
    sv: "Du kan även nå oss via Email",
  },
  or_by_web_form: { en: "", sv: "Eller via följande formulär" },
  Organization_Number: { en: "{}", sv: "Organisationsnummer" },
  Contact_us_if_you_havy_any_questions: {
    en: "",
    sv: "Kontakta gärna oss om du inte hittar svar på din fråga",
  },
  PriceList: { en: "Pricelist", sv: "Prislista" },
  HappyCustomerGuaranteeAlwaysIncluded:{en:"", sv:"Nöjdkundgaranti ingår alltid!"}
};
