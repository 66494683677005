import { Box, Text } from "grommet";
import { useEffect } from "react";

const NotFoundPage = () => {
  useEffect(() => {
    let meta = document.getElementById("indexing");
    meta?.setAttribute("name", "robots");
    meta?.setAttribute("content", "noindex");

    return () => {
      meta?.removeAttribute("name");
      meta?.removeAttribute("content");
    };
  }, []);
  return (
    <>
      <Box style={{ minHeight: "60px" }} background={{ color: "brand" }}></Box>
      <Box alignContent="center" animation={"jiggle"}>
        <Text alignSelf="center" size="10em">
          404
        </Text>
        <Text
          alignSelf="center"
          style={{ fontFamily: "monospace" }}
          size="xsmall"
        >
          Hoppsan!
        </Text>
        <div style={{ minHeight: "10em" }}></div>
      </Box>
    </>
  );
};

export default NotFoundPage;
