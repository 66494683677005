export type faq_list_type = {
  faq: faq[];
};

export type faq = {
  id: number;
  question: string;
  answer: string;
};

export const faq_list = (l: string) => {
  return [
    {
      id: 1,
      question: "Vad gäller med RUT?",
      answer:
        "Våra städ- och trädgårdstjänster ger rätt till skattereduktion för privat personer. Det innebär att de är hushållsnära tjänster (RUT) och att du som kund bara betalar 50 procent av arbetskostnaden. Skattereduktionen tar vi på Städkamrater hand om när du får fakturan, allt för att göra det så enkelt som möjligt för dig som kund. Har du frågor eller funderingar kring RUT är du varmt välkommen att ta kontakt med oss eller läs mer på Skatteverkets hemsida.",
    },
    {
      id: 2,
      question:
        "Jag skulle behöva hjälp med ytterligare städtjänster, går det att lösa när ni ändå är hemma hos mig?",
      answer:
        "Vi anpassar våra städuppdrag individuellt efter våra kunders önskemål. Om du vill lägga till ett moment i städningen, som exempelvis fönsterputsning eller tvätt och vikning så är bara att du kontaktar oss inför ditt nästa städpass. Vi ser då till att lägga till fler timmar till ditt ordinarie städpass utefter dina behov.",
    },
    {
      id: 3,
      question: "Kan jag vara hemma när ni städar?",
      answer:
        "Självklart! Vi kräver inte att du är hemma när vi kommer men du får naturligtvis vara det om du vill eller behöver.",
    },
    {
      id: 4,
      question: "Vad händer om jag inte blir nöjd med städningen?",
      answer:
        "Vårt mål är att alltid ha nöjda kunder som känner sig trygga med oss. Därför erbjuder vi alla kunder 100 % nöjd kund-garanti efter utfört arbete. Skulle en städning mot förmodan inte leva upp till det resultat vi utlovat ser vi till att åtgärda felet utan någon extra kostnad.",
    },
    {
      id: 5,
      question: "Är det samma person som städar varje gång?",
      answer:
        "Vår ambition är att du ska få samma städare vid varje städtillfälle. Om din ordinarie städare är sjuk eller får förhinder kan en annan städare komma ut istället. ",
    },
    {
      id: 6,
      question: "Vad händer om något går sönder?",
      answer:
        "Städkamrater har en ansvarsförsäkring. Detta innebär att om Städkamrater eller dess personal av oaktsamhet (vårdslöshet) förorsakar sak- eller personskada ersätter Städkamrater sådan skada (direkt skada eller direkt förlust).",
    },
    {
      id: 7,
      question: "Vad är det för uppsägningstid?",
      answer:
        "Vi har ingen uppsägningstid. Avtalet upphör omedelbart efter att kunden skickat in uppsägningen.",
    },
    {
      id: 8,
      question:
        "Hur gör vi med det praktiska som nyckelkvittering och eventuell larmvisning?",
      answer:
        "Vi brukar alltid boka in ett kundbesök innan första städtillfället. Det är ett perfekt tillfälle för en nyckelkvittering och larmvisning. När vi väl har mottagit nyckeln förvaras dessa inlåsta i ett kassaskåp, märkta enbart med ett kundnummer.",
    },
    {
      id: 9,
      question:
        "Har ni städutrustning, städmaterial och trädgårdsredskap med er?",
      answer:
        "Vi har allt städmaterial, städutrusning och trädgårdsredskap med oss. Du behöver inte oroa dig för detta, allt är bara för att du ska ha så lite bekymmer som möjligt.",
    },
    {
      id: 10,
      question: "Hur behandlar ni mina personuppgifter?",
      answer:
        "Vi sparar endast dessa uppgifter i syfte att kunna planera och schemalägga din städning samt ansöka om RUT-avdrag",
    },
  ];
};
