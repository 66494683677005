import { image_db } from "./image_list";

export const load_pic = (name: string, size?: string) => {
  switch (size) {
    case "small":
      return image_db[`s_${name}`];
    case "medium":
      return image_db[`m_${name}`];
    default:
      return image_db[`l_${name}`];
  }
};
