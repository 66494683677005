const setMetaTagForCanonicalUrl = () => {
  document
    .getElementById("can_tag")
    ?.setAttribute(
      "href",
      "https://www.stadkamrater.se" + window.location.pathname
    );
};

export default setMetaTagForCanonicalUrl;
