import { createContext, useContext } from "react";
import HeaderBar from "./components/HeaderBar";
import { ResponsiveContext } from "grommet";
import FooterBar from "./components/Footer";
import Content from "./components/Content";
import { BrowserRouter } from "react-router-dom";
import getLanguage from "./helpers/getLanguage";

export const LanguageContext = createContext(getLanguage());

function App() {
  const size = useContext(ResponsiveContext);

  return (
    <>
      <BrowserRouter>
        <LanguageContext.Provider value={getLanguage()}>
          <HeaderBar size={size} />
          <Content size={size} />
          <FooterBar />
        </LanguageContext.Provider>
      </BrowserRouter>
    </>
  );
}

export default App;
